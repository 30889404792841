import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  Typography,
  TextField, Box,
} from "@mui/material";
import {ArrowForwardIosSharp} from "@mui/icons-material";
import {Controller, FormProvider, useForm} from "react-hook-form";
import {date, object, TypeOf, z} from "zod";
import {TimePicker} from "@mui/x-date-pickers";
import Util from "../../../../utils/Util";
import Tooltip, {TooltipProps, tooltipClasses} from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import {styled} from '@mui/material/styles';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import GenericService from "@/services/GenericService";
import {ATTEndpoints} from "@/services/att/endpoints";
import FormControl from "@/pages/auth/Login/components/SignUp";
import {zodResolver} from "@hookform/resolvers/zod";
import {useTranslation} from "react-i18next";
import NGInputLabel from "@/components/ngteco-form/NGInputLabel";
import SnackbarUtil from "@/utils/SnackbarUtil";
import NGTextField from "@/components/ngteco-form/NGTextField";


const CustomWidthTooltip = styled(({className, ...props}: TooltipProps) => (
  <Tooltip {...props} classes={{popper: className}}/>
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: '530px',
    backgroundColor: "white",
    border: "solid #2BA9DE 1px",
    color: "gray",
    fontSize: 15,
    lineHeight: 2,
    height: "150px",
    "& .MuiTooltip-arrow": {
      top: "-5px !important",
      "&::before": {
        border: "solid #2BA9DE 1px",
        backgroundColor: "white",
      },
    },
  },
});

const HelpOutlineOutlinedIconCustom = styled(HelpOutlineOutlinedIcon)`
  &:hover {
    color: #2BA9DE;
  }
`;
const IconButtonCustom = styled(IconButton)`
  &:hover {
    color: #2BA9DE;
  }
`;

export type BreakTimeSettingFields = {
  break_start_1: any;
  break_end_1: any;
  pairing_rule_1: string;
  break_start_2?: any;
  break_end_2?: any;
  pairing_rule_2?: string;
  break_start_3?: any;
  break_end_3?: any;
  pairing_rule_3?: string;
}

export type PatternSettingFields = {
  day_change: string;
  punch_interval: number;
  max_hrs: number;
  pairing: string;
  break_time?: BreakTimeSettingFields;
  overtime_remind: number;
  required_record: string;
}

type PatternSettingProps = {
  pattern: PatternSettingFields;
  view_data: any;
  schedule_used?: any;
  option?: any;
}

const PatternSetting = (props: PatternSettingProps, ref: any) => {
  const {t} = useTranslation();
  const defaultPattern = props.pattern;
  const param_id = props.view_data;
  const schedule_used = props.schedule_used;
  const ruleMode_tips = t("att.timeSheet.ruleMode Tip");
  const longestWorking_tips = t("att.timeSheet.longestWorking Tip");
  const overtime_remind_tips = t("att.timeSheet.overtime_remind_tips");
  const [PairingRuleText, SetPairingRuleText] = React.useState('');
  const [MaxHoursText, setMaxHoursText] = React.useState<string>("");
  const [MaxHoursError, setMaxHoursError] = React.useState<boolean>(false);
  const [DayChangeValue, setDayChangeValue] = React.useState<any>(defaultPattern.day_change);

  const [IntervalError, setIntervalError] = useState<boolean>(false);
  const [IntervalErrorText, setIntervalErrorText] = useState<string>("");
  const patternSchema = object({
    day_change: z.string(),
    punch_interval: z.number()
      .min(1, "Minimum is 1")
      .max(999, "Maximum is 999"),
    max_hrs: z.number()
      .min(1, "Minimum is 1")
      .max(24, "Maximum is 24"),
    pairing: z.string(),
    overtime_remind: z.number()
      .min(0, "Minimum is 0")
      .max(480, "Maximum is 480"),
    required_record: z.string()
  });

  type PatternInput = TypeOf<typeof patternSchema>;


  const patternSettingForm = useForm<PatternInput>({
    defaultValues: defaultPattern,
    resolver: zodResolver(patternSchema)
  })
  const {
    register,
    trigger,
    setError,
    formState: {errors},
  } = patternSettingForm;

  const handleDayTimeText = (Form: any) => {
    const pairing = Form.getValues("pairing");
    const required_record = Form.getValues("required_record");
    if (pairing == "first_last") {
      if (required_record == "any") {
        SetPairingRuleText(t("att.timeSheet.PairingRuleText_11"))
      } else {
        SetPairingRuleText(t("att.timeSheet.PairingRuleText_12"))
      }
    } else {
      if (required_record == "any") {
        SetPairingRuleText(t("att.timeSheet.PairingRuleText_21"))
      } else {
        SetPairingRuleText(t("att.timeSheet.PairingRuleText_22"))
      }
    }
  };
  useEffect(() => {
      handleDayTimeText(patternSettingForm)
      if (props.option == "working") {
        patternSettingForm.setValue("max_hrs", 24)
        setMaxHoursError(false);
        setMaxHoursText("")
      }
    },
    [patternSettingForm.getValues("pairing"), patternSettingForm.getValues("required_record"), props.option]);

  useEffect(() => {
    if (param_id) {
      GenericService.retrieve(ATTEndpoints.ATT_TIMESHEET_URI, `${param_id}`).then((res) => {
        patternSettingForm.setValue("max_hrs", Number(res.data?.pattern.max_hrs));
        patternSettingForm.setValue("punch_interval", Number(res.data?.pattern.punch_interval));
        patternSettingForm.setValue("overtime_remind", Number(res.data?.pattern?.overtime_remind));
        patternSettingForm.setValue("pairing", res.data?.pattern.pairing);
        patternSettingForm.setValue("required_record", res.data.pattern?.required_record);
        setDayChangeValue(res.data.pattern?.day_change);
      })
    }
  }, [])
  useImperativeHandle(ref, () => ({
    pattern: async () => {
      const isValid = await trigger();
      return patternSettingForm.getValues()
    }
  }));
  return (
    <FormProvider {...patternSettingForm}>
      <Grid container={true} rowSpacing={1} columnSpacing={6} component={"form"}>
        <Grid item xs={12} sx={{p: "0 16px"}}>
          <Grid container={true} rowSpacing={2} columnSpacing={6} component={"form"}>
            <Grid item={true} xs={4}>
              <NGInputLabel label={t("common.Day Change Time")}/>
              <Controller
                control={patternSettingForm.control}
                name={"day_change"}
                render={({field, fieldState}) =>
                  <TimePicker
                    ampm={false}
                    value={(typeof DayChangeValue === "string") ? Util.time2datetime(DayChangeValue) : DayChangeValue}
                    onChange={(newValue) => {
                      field.onChange(newValue?.toString().slice(16, 21));
                      setDayChangeValue(newValue)
                    }}
                    disabled={schedule_used!=0}
                    renderInput={(fieldProps) => {
                      return <TextField
                        size={"medium"}
                        fullWidth={true}
                        {...fieldProps}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message || ""}
                      />
                    }}
                  />
                }
              />
            </Grid>
            <Grid item={true} xs={4}>
              <Grid sx={{display: "flex", flexDirection: "row"}}>
                <NGInputLabel label={t("common.Statistics rule mode")}/>
                <CustomWidthTooltip title={ruleMode_tips} placement="left-end" arrow>
                  <IconButtonCustom sx={{"&:hover": {backgroundColor: "transparent"}, marginTop: "-8px"}}>
                    <HelpOutlineOutlinedIconCustom sx={{fontSize: 20}}/>
                  </IconButtonCustom>
                </CustomWidthTooltip>
              </Grid>
              <Box sx={{display: 'flex', alignItems: 'flex-end', marginTop: "-4px"}}>
                <Controller
                  control={patternSettingForm.control}
                  name={"pairing"}
                  render={({field}) => {
                    return (
                      <Select
                        {...field}
                        size={"medium"}
                        fullWidth={true}
                        disabled={schedule_used!=0}
                        defaultValue={"first_last"}
                      >
                        <MenuItem value={"first_last"}>{t("att.timeSheet.First And Last")}</MenuItem>
                        <MenuItem value={"odd_even"}>{t("att.timeSheet.Even and Odd")}</MenuItem>
                      </Select>
                    )
                  }}
                />
                <Controller
                  control={patternSettingForm.control}
                  name={"required_record"}
                  render={({field}) => {
                    return (
                      <Select
                        {...field}
                        size={"small"}
                        disabled={schedule_used!=0}
                        defaultValue={"any"}
                        sx={{display: 'none', fontSize: "14px"}} // current version is useless
                        onChange={(newValue) => {
                          const value = newValue.target.value;
                          patternSettingForm.setValue("required_record", value)
                          handleDayTimeText(patternSettingForm)
                        }}
                      >
                        <MenuItem sx={{fontSize: "14px"}} value={"any"}>{t("att.timeSheet.Any")}</MenuItem>
                        {/* <MenuItem value={"in_out"}>IN/OUT</MenuItem> */}
                      </Select>
                    )
                  }}
                />
              </Box>
            </Grid>
            <Grid item={true} xs={4}>
              <NGInputLabel label={t("common.Punch Interval(Min)")}/>
              <Controller
                control={patternSettingForm.control}
                name={"punch_interval"}
                render={({field}) => {
                  return (
                    <TextField
                      size={"medium"}
                      disabled={schedule_used!=0}
                      fullWidth={true}
                      type="number"
                      value={Number(field.value)}
                      error={IntervalError}
                      helperText={IntervalErrorText}
                      {...register("punch_interval")}
                      onChange={(e) => {
                        let value = Number(e.target.value)
                        if (value > 999) {
                          value = 999
                        } else if (value < 0) {
                          value = 0
                        }
                        patternSettingForm.setValue("punch_interval", value)
                      }}
                    />
                  )
                }}
              />
            </Grid>
            { props.option === "clocking" && <Grid item={true} xs={4}>
              <Box>
                <Grid sx={{display: "flex", flexDirection: "row"}}>
                  <NGInputLabel label={t("common.Overtime Reminder")}/>
                  <CustomWidthTooltip title={overtime_remind_tips} placement="left-end" arrow>
                    <IconButtonCustom sx={{"&:hover": {backgroundColor: "transparent"}, marginTop: "-8px"}}>
                      <HelpOutlineOutlinedIconCustom sx={{fontSize: 20}}/>
                    </IconButtonCustom>
                  </CustomWidthTooltip>
                </Grid>
                <Controller
                  control={patternSettingForm.control}
                  name={"overtime_remind"}
                  render={({field}) => {
                    return (
                      <Stack spacing={1} direction="row" alignItems={"center"}>
                        <TextField
                          size={"medium"}
                          disabled={schedule_used!=0}
                          // fullWidth={true}
                          type="number"
                          value={Number(field.value)}
                          // error={!!errors['overtime_remind']}
                          // helperText={errors['overtime_remind']?.message || ""}
                          {...register("overtime_remind")}
                          onChange={(e) => {
                            let value = Number(e.target.value)
                            if (value < 0) {
                              value = 0
                            } else if (value > 480) {
                              value = 480
                            }
                            patternSettingForm.setValue("overtime_remind", value)
                          }}
                        />
                        <Typography
                          sx={{color: "rgba(0, 0, 0, 0.6)"}}>{t("common.minutes after check-out")}</Typography>
                      </Stack>
                    )
                  }}
                />
              </Box>
            </Grid>}
            { props.option === 'clocking' && <Grid item={true} xs={4}>
              <Grid sx={{display: "flex", flexDirection: "row"}}>
                <NGInputLabel label={t("common.Longest Work Hours")}/>
                <CustomWidthTooltip title={longestWorking_tips} placement="left-end" arrow>
                  <IconButtonCustom sx={{"&:hover": {backgroundColor: "transparent"}, marginTop: "-8px"}}>
                    <HelpOutlineOutlinedIconCustom sx={{fontSize: 20}}/>
                  </IconButtonCustom>
                </CustomWidthTooltip>
              </Grid>
              <Controller
                control={patternSettingForm.control}
                name={"max_hrs"}
                render={({field}) => {
                  return (
                    <TextField
                      size={"medium"}
                      type="number"
                      fullWidth={true}
                      disabled={schedule_used!=0}
                      inputProps={{maxLength: '2'}}
                      value={Number(field.value)}
                      {...register("max_hrs")}
                      onChange={(e) => {
                        let value = Number(e.target.value)
                        if (value > 24) {
                          value = 24
                        } else if (value < 1) {
                          value = 1
                        }
                        patternSettingForm.setValue("max_hrs", value)
                      }}
                    />
                  )
                }}
              />
            </Grid>}
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  )
};
export default forwardRef(PatternSetting)

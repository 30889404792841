import React, {useState, useEffect, useRef, useContext, Fragment} from "react";
import {ATTEndpoints} from "../../../../../services/att/endpoints";
import CustomDataGrid, {DataGridRef} from "../../../../../components/data-grid/CustomDataGrid";
import {
  GridColDef,
  GridFilterModel,
  GridFilterOperator,
  GridFilterItem,
  GridFilterInputValueProps, GridRenderCellParams,
} from "@mui/x-data-grid";
import {
  Box,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  TextField,
  Dialog as MuiDialog,
  DialogTitle,
  DialogContent,
  Typography,
  Alert,
  Button, Stack,
} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers";
import {useTranslation} from "react-i18next";
import WebURL from "@/urls";
import CloseIcon from "@mui/icons-material/Close";
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import ExportAction from "@/components/actions/Export";
import SnackbarUtil from "@/utils/SnackbarUtil";
import Util from "@/utils/Util";
import moment from "moment";
import TimecardService from "@/services/att/services/TimecardService";
import TransactionService from "@/services/att/services/TransactionService";
import {DataGridContext, DataGridDispatchContext} from "@/components/data-grid/DataGridContext";
import TimeSelectDrawer from "@/pages/att/Timecard/TimeSelectDrawer";

const SUBMIT_FILTER_STROKE_TIME = 500;

function InputNumberInterval(props: GridFilterInputValueProps) {
  const {t} = useTranslation();
  const {item, applyValue} = props;

  const filterTimeout = useRef<any>();
  const [filterValueState, setFilterValueState] = useState<[Date, Date]>([item.value[0], item.value[1]]);

  useEffect(() => {
    return () => {
      clearTimeout(filterTimeout.current);
    };
  }, []);

  const updateFilterValue = (lowerBound: Date, upperBounding: Date) => {
    clearTimeout(filterTimeout.current);
    setFilterValueState([lowerBound, upperBounding]);

    filterTimeout.current = setTimeout(() => {
      applyValue({...item, value: [lowerBound, upperBounding]});
    }, SUBMIT_FILTER_STROKE_TIME)
  };

  const handleUpperFilterChange = (event: any) => {
    const newUpperBound = event;
    updateFilterValue(filterValueState[0], newUpperBound);
  };
  const handleLowerFilterChange = (event: any) => {
    const newLowerBound = event;
    updateFilterValue(newLowerBound, filterValueState[1]);
  };

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'end',
        width: "240px"
      }}
    >
      <DatePicker
        label={t("common.Start Date")}
        value={filterValueState[0] || new Date(new Date().getFullYear(), new Date().getMonth(), 1)}
        maxDate={filterValueState[1]}
        inputFormat={"dd-MM-yyyy"}
        onChange={handleLowerFilterChange}
        renderInput={(params) =>
          <TextField
            variant="standard"
            {...params}
          />}
      />
      <DatePicker
        label={t("common.End Date")}
        value={filterValueState[1]}
        minDate={filterValueState[0]}
        inputFormat={"dd-MM-yyyy"}
        onChange={handleUpperFilterChange}
        renderInput={(params) =>
          <TextField
            variant="standard"
            {...params}
          />}
      />
    </Box>
  )
}

const punchRecordStyle = {
  '& .MuiPaper-root': {width: '60vw', top: '-5vh'},
  '.MuiDialog-paper': {maxWidth: '100vw'},
};

const TransactionGrid: React.FC = () => {
  const {t} = useTranslation();
  const today = new Date()
  const firstDay = new Date()
  firstDay.setDate(1)
  const [mended, setMended] = useState<boolean>(false);
  const dataGridRef = useRef<DataGridRef>();
  const [info, setInfo] = useState<boolean>(false);
  const [filterParams, setFilterParams] = useState<Record<any, any>>({
    date_range: [
      firstDay.toISOString().slice(0, 10),
      today.toISOString().slice(0, 10)
    ]
  });
  const [exportFormat, setExportFormat] = useState<string>("");
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [filterDate, setFilterDate] = useState<GridFilterModel>({
    items: [
      {
        id: 1,
        columnField: 'att_date',
        value: [firstDay.toISOString().slice(0, 10), today.toISOString().slice(0, 10)],
        operatorValue: "between",
      }
    ]
  })

  const InfoDialog = () => {
    return (
      <MuiDialog
        open={info}
        // onClose={()=>setInfo(false)}
        sx={punchRecordStyle}
      >
        <DialogTitle>
          {t("common.Usage Tips")}
          <IconButton
            aria-label="close"
            onClick={() => setInfo(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon/>
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{lineHeight: '25px', color: 'gray'}}>
          <Box sx={{p: 1}}> 1. {t("dms.uploadTransactionTip")}</Box>
          <Box sx={{p: 1}}> 2. {t("dms.punchRecordTip")}</Box>
        </DialogContent>
      </MuiDialog>
    )
  }


  const punchDateOperators: GridFilterOperator[] = [
    {
      label: t("common.Between"),
      value: "between",
      getApplyFilterFn: (filterItem: GridFilterItem, column) => {
        if (!Array.isArray(filterItem.value) || filterItem.value.length !== 2) {
          return null;
        }
        return ({value}) => {
          return true;
        };
      },
      InputComponent: InputNumberInterval
    },
  ];
  const SourceField = (params: GridRenderCellParams) => {
    let punch_from = "";
    if (params.value == 'manual') {
      punch_from = t("common.manual");
    } else {
      punch_from = params.value
    }
    return <Grid>{punch_from}</Grid>;
  };
  useEffect(() => {
    if (exportFormat) {
      const export_meta = {
        fields: {
          employee_code: {
            alias: t("common.Person ID"),
          },
          employee_name: {
            alias: t("common.Person Name"),
          },
          att_date: {
            alias: t("common.Punch Date"),
          },
          attendance_status: {
            alias: t("common.Attendance record"),
          },
          timezone: {
            alias: t("att.schedule.TimeZone"),
          },
          punch_from: {
            alias: t("att.schedule.punch from"),
          },
        },
        title: t("common.View Attendance Punch"),
        file_type: exportFormat,
        share: [],
      };
      // setLoading(true)
      try {
        const data = export_meta;
        const params = `&keyword=${searchKeyword}&date_range=${moment(filterParams?.date_range[0]).format(
          "yyyy-MM-DD"
        )}&date_range=${moment(filterParams?.date_range[1]).format(
          "yyyy-MM-DD"
        )}`;
        const res = TransactionService.download_data(
          params,
          data,
        ).then((response) => {
          const blob = new Blob([response?.data], {
            type: response?.headers["content-type"],
            endings: "transparent",
          });
          const url = URL.createObjectURL(blob);
          const aLink = document.createElement("a");
          aLink.style.display = "none";
          aLink.href = url;
          document.body.appendChild(aLink);
          aLink.click();
          URL.revokeObjectURL(url);
          document.body.removeChild(aLink);
          SnackbarUtil.success(t("common.success"));
        });
      } catch (e) {
        // console.log(e)
      } finally {
        // setLoading(false)
        // setUpload(false)
      }
      setExportFormat("");
    }
  }, [exportFormat]);

  const columns: GridColDef[] = [
    {
      field: 'employee_name', headerName: t("common.Person Name"), minWidth: 200, flex: 0.2, filterable: false,
      renderCell: (params) => {
        return (
          <Grid style={{display: "flex", alignItems: "center"}}>
            {/* <AvatarIcon name={params.row.first_name} /> */}
            <Box style={{padding: '0 8px'}}>{params.row.first_name} {params.row.last_name}</Box>
          </Grid>
        )
      }
    },
    {
      field: 'employee_code',
      headerName: t("common.Person ID"),
      type: "string",
      minWidth: 200,
      flex: 0.2,
      filterable: false
    },
    {
      field: 'att_date', headerName: t("common.Punch Date"), type: "date", minWidth: 150, flex: 0.2,
      filterOperators: punchDateOperators,
    },
    {
      field: 'attendance_status',
      headerName: t("common.Attendance record"),
      flex: 0.8,
      minWidth: 150,
      filterable: false,
      renderCell: (params) => {
        if (params.row.punch_from === 'manual') {
          return (
            <Grid color={mended ? "red" : "black"}>{params.row.attendance_status}</Grid>
          )
        } else {
          return (
            <Grid color={"black"}>{params.row.attendance_status}</Grid>
          )
        }
      }
    },
    {field: 'timezone', headerName: t("att.schedule.TimeZone"), minWidth: 150, flex: 1},
    {field: 'punch_from', headerName: t("att.schedule.punch from"), flex: 1, renderCell: SourceField,},
  ]
  const ExtraAction = (props: any) => {
    const {searchKeyword} = useContext(DataGridContext)
    setSearchKeyword(searchKeyword)
    return (
      <Fragment>
      </Fragment>
    )
  }

  const toolbar = {
    title: t("common.Timecard"),
    searchTips: t("common.Search by Person ID, Person Name"),
    search: true,
    // info: {setDisplay: setInfo,},
    breadcrumbs: [
      {breadcrumbName: t("common.View Attendance Punch"), path: WebURL.ATT_TIMECARD_TIMECARD}
    ],
    extraComponent:
      <Grid sx={{paddingRight: "1vw"}}>
        <FormControlLabel
          labelPlacement="start"
          label={t("common.Show mended records")}
          control={<Switch color="primary" checked={mended} onChange={() => setMended(!mended)}/>}
        />
      </Grid>
    ,
    actions: [
      {
        key: 'about', iconFont: 'icon-ic_about', helpTxt: t("common.Usage Tips"),
        callback: async () => {
          setInfo(true)
        }
      }
    ],
    children: (
      <ExtraAction
        filterParams={filterParams}
        setExportFormat={setExportFormat}
      />
    ),
    extraComponentOnRight:
      <Grid sx={{display: "flex"}}>
        <Grid sx={{marginTop: "9px"}}>
          <ExportAction
            setExportFormat={async (fileType: string) => {
              setExportFormat(fileType);
            }}
          />
        </Grid>
        <Grid sx={{marginTop: "2px"}}><TimeSelectDrawer/></Grid>
      </Grid>
  }

  return (
    <>
      <Box
        sx={{
          '& .MuiDataGrid-row-height-no-limit': {
            maxHeight: '180px!important'
          },
        }}
      >
        <CustomBreadcrumbs record={[{label: t("common.View Attendance Punch")}]}/>
        <CustomDataGrid
          columns={columns}
          ref={dataGridRef}
          uri={ATTEndpoints.ATT_TRANSACTION_URI}
          // checkboxSelection={true}
          requestParams={filterParams}
          filterModel={filterDate}
          onFilterModelChange={(model) => {
            if (typeof (model.items[0].value[0]) == 'object') {
              model.items[0].value[0] = model.items[0].value[0].toISOString().slice(0, 10)
            }
            if (typeof (model.items[0].value[1]) == 'object') {
              model.items[0].value[1] = model.items[0].value[1].toISOString().slice(0, 10)
            }
            setFilterDate(model)
            setFilterParams({
              date_range: [
                model.items[0].value[0],
                model.items[0].value[1]
              ]
            })
            dataGridRef.current?.refresh(true);
          }}
          toolbar={toolbar}
          localeText={{
            filterPanelColumns: t("common.Columns"),
            filterPanelOperators: t("common.Operators"),
          }}
        />
      </Box>
      <InfoDialog/>
    </>
  )
}
export default TransactionGrid

import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {Box, Button, Grid, Tooltip, Typography,} from "@mui/material";
import {ATTEndpoints} from "../../../services/att/endpoints";
import React, {useEffect, useState} from "react";
import GenericService from "@/services/GenericService";
import {useTranslation} from "react-i18next";
import ActionButton from "@/components/actions/ActionButton";
import {TitleTypography} from './commonCmp'
import Dialog from "@/components/dialog";

export function CustomNoRowsGrid() {
  const {t} = useTranslation();
  return (
    <Box sx={{
      mt: 1,
      color: "#000000",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: 'column',
      paddingTop: "20%",
    }}>
      <Box>
        {t("common.No Rows")}
      </Box>
    </Box>
  );
}


type GridData = {
  data: any;
  total: number;
  num_pages: number;
};
type FilterType = {
  date_range: [any, any];
};

const customDataGridStyle = {
  height: "100%",
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "#F0F1F4!important",
    color: "#304669!important",
  },
  ".MuiDataGrid-columnHeader:focus-within": {outline: "none"},
  ".MuiDataGrid-cell": {borderBottom: "0px", color: "#304669"},
  ".MuiDataGrid-cell:focus-within": {
    outline: "none!important",
    color: "#2BA9DE!important",
  },
  ".MuiDataGrid-columnSeparator": {display: "none !important"},
  ".MuiSvgIcon-root": {backgroundColor: "transparent"},
  ".MuiDataGrid-root": {padding: "8px", borderRadius: "4px"},
  ".MuiDataGrid-row": {
    borderBottom: "1px solid #E7EBF0",
    maxHeight: "48px!important",
    minHeight: "48px!important",
  },
  ".MuiCheckbox-root": {color: "#D1D1D1"},
  ".MuiDataGrid-cellContent": {
    color: "#304669!important",
    fontFamily: "Roboto",
    letterSpacing: "0.4px",
    fontSize: "14px",
  },
  ".MuiDataGrid-virtualScrollerRenderZone": {backgroundColor: "#FFFF"},
  ".MuiDataGrid-footerContainer": {
    display: "block",
    width: "100%",
    border: "0px",
    height: "100%",
  },
  ".MuiTablePagination-spacer": {display: "none"},
  ".MuiTablePagination-actions": {
    position: "absolute",
    right: "0px",
    backgroundColor: "#FFF",
    '& .MuiIconButton-root': {
      padding: '4px',
      margin: '0px 4px',
      border: '1px solid #DEE0E3',
      'border-radius': '6px',
    }
  },
  ".MuiTablePagination-displayedRows": {position: "absolute", right: "110px"},
  // '.MuiDataGrid-virtualScroller': {overflow: 'hidden'},
  ".MuiTablePagination-select": {
    backgroundColor: "#F0F1F4",
    borderRadius: "4px!important",
  },
  ".MuiTablePagination-toolbar": {paddingLeft: "0px"},
  ".MuiIconButton-root.Mui-disabled": {
    backgroundColor: "#F0F1F4",
    borderRadius: "0px",
  },
  // '.MuiIconButton-root': {borderRadius: '0px'},
  ".Mui-checked": {color: "rgb(54, 201, 109)!important"},
};

const AttendanceRecord: React.FC<any> = (props) => {
  const {t} = useTranslation();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [gridEmpData, setEmpGridData] = useState<GridData>({
    data: [],
    total: 0,
    num_pages: 0,
  });
  const [params, setParams] = useState<FilterType>({
    date_range: [new Date(), new Date()],
  });

  const {pageSize, setPageSize, page, setPage, refresh, setRefresh} = props;

  const fetchEmpGridData = () => {
    setLoading(true);
    const {date_range} = params;
    date_range.forEach((element, index) => {
      params.date_range[index] = new Date(element).toISOString().slice(0, 10);
    });
    GenericService.list(ATTEndpoints.ATT_PUNCH_RECORD_URI, {
      current: page + 1,
      pageSize: pageSize,
      ...params,
    })
      .then((response) => {
        setEmpGridData(response?.data);
      })
      .catch((error) => {
        if (error.response?.status < 500) {
          // do something when status is 4xx.
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchEmpGridData();
  }, [page, pageSize, refresh]);

  const refreshGridData = () => {
    setRefresh(refresh + 1);
  };

  const columns: GridColDef[] = [
    {
      field: "employee_name",
      headerName: t("common.Person Name"),
      flex: 0.1,
      renderCell: (params) => {
        return (
          <Grid style={{display: "flex", alignItems: "center"}}>
            <Box style={{padding: "0 8px"}}>
              {params.row?.first_name} {params.row?.last_name}
            </Box>
          </Grid>
        );
      },
    },
    {
      field: "punch_record",
      headerName: t("common.Punch Record"),
      flex: 0.3,
      renderCell: (params) => {
        const res: any[] = [];
        const more_res: any[] = [];
        const current_width = params?.colDef?.computedWidth
        const wrap_index = current_width ? Math.floor(current_width / 63) : 9
        const max_display_index = wrap_index * 3 - 3
        const readMoreOnClick = () => {
          Dialog.ok({
            title: t("More Records"),
            content: more_res,
          });
        };
        params.row.attendance_status.forEach((element: any, index: number) => {
          const val = element.punch_time.split(":");
          const waited_push_item = element.status === "warning"
            ? <Grid key={index} component={'a'} color={"red"}>{`${val.join(":")}; `}</Grid>
            : <Grid key={index} component={'a'} color={"black"}>{`${val.join(":")}; `}</Grid>
          if (index < max_display_index) {
            res.push(waited_push_item)
            if (index > 1 && (index + 1) % wrap_index === 0) { // default index is 0. Start a new line
              res.push(<br key={"br" + index}></br>)
            }
          } else {
            more_res.push(waited_push_item)
          }
        });
        return (
          <Grid sx={{textAlign: "justify"}}>
            {res}
            {more_res.length > 0 ?
              <Button size="small" onClick={readMoreOnClick}>{t("More Records...")}</Button> : <></>}
          </Grid>
        )
      },
    },
  ];

  return (
    <Grid
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: "50px",
          marginLeft: "5px",
        }}
      >
        <TitleTypography>
          {t("common.Daily Attendance Record")}
        </TitleTypography>

        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box style={{
            display: "inline-block", width: "6px", height: "6px", borderRadius: "50%",
            backgroundColor: "#F65555"
          }}></Box>
          <Typography
            sx={{
              fontWeight: "400 !important",
              fontSize: "12px !important",
              color: "#999999  !important",
              marginLeft: "10px !important",
            }}
          >
            {t("common.The red attendance record is mended data")}
          </Typography>
          <Tooltip title={t("common.refresh")}>
            <div>
              <ActionButton
                icon="icon-ic_refresh"
                handleClick={refreshGridData}
              ></ActionButton>
            </div>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid
        sx={{
          flexGrow: 1
        }}
      >
        <DataGrid
          columns={columns}
          loading={isLoading}
          pagination={true}
          paginationMode={"server"}
          pageSize={pageSize}
          headerHeight={40}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 25, 50, 100]}
          page={page}
          onPageChange={(newPage) => {
            setPage(newPage);
          }}
          rows={gridEmpData?.data}
          rowCount={gridEmpData?.total ? gridEmpData?.total : 0}
          hideFooterSelectedRowCount={true}
          disableColumnMenu={true}
          style={{border: "none"}}
          components={{NoRowsOverlay: CustomNoRowsGrid}}
          componentsProps={{
            pagination: {
              labelRowsPerPage: t("common.datagrid.paginate"),
            },
          }}
          sx={customDataGridStyle}
        />
      </Grid>
    </Grid>
  );
};
export default AttendanceRecord;

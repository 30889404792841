import React, {forwardRef, Fragment, useImperativeHandle, useState} from "react";
import {useTranslation} from "react-i18next";
import {
  Button,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import {Delete} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import DepartmentService from "@/services/hr/DepartmentService";
import {useSnackbar} from "notistack";
import {CancelButton, SaveButton} from "@/components/data-grid/CustomButton";
import SnackbarUtil from "@/utils/SnackbarUtil";
import GenericService from "@/services/GenericService";
import {ATTEndpoints} from "@/services/att/endpoints";
import ActionButton from "@/components/actions/ActionButton";

const dialogStyle = {
  "& .MuiPaper-root": {width: "400px", top: "1vh", overflowX: "hidden"},
  ".MuiDialog-paper": {maxWidth: "100vw"},
  overflowX: "hidden",
};

export function DeleteAction({setInfo}: any) {
  const {t} = useTranslation();

  return (
    <Tooltip title={t("common.Delete Timesheet?")}>
      <ActionButton
        title={t("common.delete")}
        icon="icon-ic_delete"
        handleClick={() => {
          setInfo(true);
        }}
      ></ActionButton>
    </Tooltip>
  );
}


const TimesheetDelete = (props: any, ref: any) => {
  const [info, setInfo] = useState<boolean>(false);
  const [timesheetIds, setTimesheetIds] = useState<any>([])
  const {t} = useTranslation()
  const {enqueueSnackbar} = useSnackbar();
  useImperativeHandle(ref, () => ({
    showDelete: async (id:string) => {
      setTimesheetIds(id)
      setInfo(false)
    }
  }));
  const toggleDrawer =
    (open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (timesheetIds.length == 0){
          setInfo(false);
          SnackbarUtil.error(t("common.Select Timesheet first"),
            {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
          return;
        }
      };
  const onSubmit = async (event: any) => {
    try {
      timesheetIds.map((timesheet: any) => {
        GenericService.destroy(ATTEndpoints.ATT_TIMESHEET_URI, `${timesheet}`).then((res) => {
          enqueueSnackbar(t("common.success"), {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
          });
        });
      });
      setTimeout(function () {
        if (props.reloadTable) {
          props.reloadTable()
        }
      }, 1500)
    } catch (e) {
      console.log("error===", e);
    } finally {
      setInfo(false);
    }
  };

  return (
    <Fragment>
      <Grid item={true} onClick={toggleDrawer(true)}>
        <DeleteAction setInfo={setInfo}/>
      </Grid>
      <Dialog
        open={info}
        // onClose={()=>setInfo(false)}
        sx={dialogStyle}
      >
        <DialogTitle style={{marginBottom: "-25px"}}>
          {t("common.Delete Timesheet?")}
          <IconButton
            aria-label="close"
            onClick={() => setInfo(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon/>
          </IconButton>
        </DialogTitle>
        <Typography sx={{p: 3}}>
          {t("common.Are you sure to delete these Timesheet?")}
        </Typography>
        <Grid style={{marginBottom: "20px", marginRight: "20px", marginLeft: "20px"}}>
          <Stack spacing={3} direction={"row"} justifyContent="flex-end">
            <CancelButton
              onClick={() => {
                setInfo(false);
              }}
            />
            <SaveButton onClick={onSubmit} children={t("common.confirm")}/>
          </Stack>
        </Grid>
      </Dialog>
    </Fragment>
  );
};

export default forwardRef(TimesheetDelete);

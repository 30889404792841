import React, {forwardRef, Fragment, useImperativeHandle, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Dialog, DialogTitle, Grid, IconButton, Stack, Tooltip, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {useSnackbar} from "notistack";
import SnackbarUtil from "@/utils/SnackbarUtil";
import ActionButton from "@/components/actions/ActionButton";
import {ReactComponent as IconManualCalc} from "@/assets/icons/IconManualCalc.svg";
import ScheduleService from "@/services/att/services/ScheduleService";

const dialogStyle = {
  '& .MuiPaper-root': {width: '700px', top: '1vh', overflowX: "hidden"},
  '.MuiDialog-paper': {maxWidth: '100vw'},
  overflowX: "hidden",
};

const ManualCalc = (props: any, ref: any) => {
  const [info, setInfo] = useState<boolean>(false)
  const {t} = useTranslation()
  const {enqueueSnackbar} = useSnackbar();
  const [scheduleIds, setScheduleIds] = useState<any>([])
  useImperativeHandle(ref, () => ({
    showCalc: async (id: string) => {
      setScheduleIds(id)
      setInfo(false)
    }
  }));
  const onSubmit = async () => {
    if (scheduleIds.length == 0) {
      SnackbarUtil.error(t("common.Select Schedule first"), {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        }
      })
      return;
    }
    try {
      ScheduleService.ManualCalc(scheduleIds).then((result) => {
        if (result.status == 200) {
          enqueueSnackbar(t("common.success"), {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          });
        } else if (result.status == 202) {
          enqueueSnackbar(t("common.calc error", {value: result?.data?.detail}), {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          });
        }
      })
    } catch (e) {
      console.log("error===", e)
    } finally {
      setInfo(false)
    }
  }

  return (
    <Fragment>
      <Grid item={true}>
        <Tooltip title={t('common.Calculate')}>
          <Grid sx={{display: "flex", marginLeft: "10px"}}>
            <IconManualCalc onClick={() => {
              onSubmit()
            }}/>
            <Grid sx={{marginLeft: "-5px", marginTop: "5px"}}>
              <ActionButton
                icon='icon-ic_about'
                handleClick={() => {
                  setInfo(true)
                }}
              > </ActionButton>
            </Grid>
          </Grid>
        </Tooltip>
      </Grid>
      <Dialog
        open={info}
        // onClose={()=>setInfo(false)}
        sx={dialogStyle}
      >
        <DialogTitle style={{marginBottom: "-25px"}}>
          {t("common.Usage Tips")}
          <IconButton
            aria-label="close"
            onClick={() => setInfo(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon/>
          </IconButton>
        </DialogTitle>
        <Grid sx={{p: 3}}>
          <Typography>
            {t('common.manual calculate tips1')}
          </Typography>
          <Typography>
            {t('common.manual calculate tips2')}
          </Typography>
        </Grid>
      </Dialog>
    </Fragment>
  )
}

export default forwardRef(ManualCalc);

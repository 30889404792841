import React, { useState } from "react";
import { Grid, Paper, Box, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import {Controller, useForm} from "react-hook-form";
import { SaveButton } from "@/components/data-grid/CustomButton";
import ControlledTextFiled from "@/components/ngteco-hook-form/ControlledTextFiled";
import { useTranslation } from "react-i18next";
import { object, TypeOf, z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import TimecardService from "@/services/att/services/TimecardService";
import SnackbarUtil from "@/utils/SnackbarUtil";
import moment from "moment";

type ManualShareProps = {
    report_type: string;
  }

const ManualShare = (props: ManualShareProps) => {
  const { t } = useTranslation();
  const today = new Date();
  today.setHours(0,0,0,0)
  const exportSchema = object({
    share: z.string().min(1, t("common.This field is required!")),
    start_date:
      z.date(
        {
          errorMap: (issue, { defaultError }) =>
            ({ message: issue.code === "invalid_date" ? t("common.Invalid date format.") : issue.code === "invalid_type" ? t("common.This field is required!") : defaultError })
        }),
    end_date:
      z.date(
        {
          errorMap: (issue, { defaultError }) =>
            ({ message: issue.code === "invalid_date" ? t("common.Invalid time format.") : issue.code === "invalid_type" ? t("common.This field is required!") : defaultError })
        }),
  })
  type ExportType = TypeOf<typeof exportSchema>;

  const manualShareSettingForm = useForm<ExportType>({
    resolver: zodResolver(exportSchema),
    defaultValues: {
      start_date: today,
      end_date: today,
    }
  });

  const timecard_export_meta = {
    fields: {
      employee_code: {
        alias: t("common.Person ID")
      },
      first_name: {
        alias: t("common.First Name")
      },
      last_name: {
        alias: t("common.Last Name")
      },
      att_date: {
        alias: t("common.Date")
      },
      timesheet_name: {
        alias: t("common.Timesheet")
      },
      clock_in: {
        alias: t("common.Clock In")
      },
      clock_out: {
        alias: t("common.Clock Out")
      },
      period_hrs: {
        alias: t("common.Clock Time(h)")
      },
      paid_break: {
        alias: t("common.Total Break Time(h)")
      },
      paid_work_deduct_break: {
        alias: t("common.Total Work Time(h)")
      },
      overtime_hrs: {
        alias: t("common.Total Overtime Time(h)"),
      },
      paid_work: {
        alias: t("common.Total Time(h)")
      },
      pairing: {
        alias: t("common.Statistic Rule Mode")
      },
      abnormal_situation: {
        alias: t("common.Abnormal Situation")
      }
    },
    title: t("common.Timecard Report"),
    file_type: "csv",
    share: []
  }

  const attendance_export_meta = {
    fields: {
      employee_code: {
        alias: t("common.Person ID")
      },
      employee_name: {
        alias: t("common.Person Name")
      },
      timesheet_names: {
        alias: t("common.Timesheet")
      },
      att_dates: {
        alias: t("common.Date")
      },
      total_late_in: {
        alias: t("common.total_late_in")
      },
      total_early_out: {
        alias: t("common.total_early_out")
      },
      total_break: {
        alias: t("common.Total_break")
      },
      total_paid_work_deduct_break: {
        alias: t("common.Total_paid_work")
      },
      total_overtime_hrs: {
        alias: t("common.Total_overtime")
      },
      total_paid_work: {
        alias: t("common.Total_work")
      }
    },
    title: t("common.Attendance Report"),
    file_type: "csv",
    share: []
  }


  const report_export_meta = props.report_type == "timecard" ? timecard_export_meta : attendance_export_meta


  const onSubmit = async (formData: any) => {
    const { share } = formData;
    let isInvalidEmail = false;
    const share_email = share.trim()
      .replace(/[\r\n\s]/g, "")
      .split(';')
      .filter((item: string) => {
        const email_pattern = /\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/;
        if (item !== "" && email_pattern.test(item)) {
          return true;
        }
        isInvalidEmail = true
        return false;
      });
    if (isInvalidEmail) {
      manualShareSettingForm.setError("share", { type: "custom", message: "invalid email format" }, { shouldFocus: true })
      return
    }
    const data = {...report_export_meta, share: share_email};
    const params = `date_range=${moment(manualShareSettingForm.getValues("start_date")).format('yyyy-MM-DD')}&date_range=${moment(manualShareSettingForm.getValues("end_date")).format('yyyy-MM-DD')}`;
    const res = await TimecardService.export_data(params, data, props.report_type).then((result)=>{
      SnackbarUtil.success(t("common.success"));
    });
  };

  return (
    <>
      <Grid component={'form'} sx={{padding: '24px'}}>
        <Grid sx={{marginBottom: '20px'}}>
          <Typography sx={{color: "#999999"}}>{t("export.Select date period")}</Typography>
        </Grid>
        <Paper elevation={0} sx={{lineHeight: '40px'}}>
          <Grid container>
            <Grid lg={8} md={8} sm={8} item>
              <Box sx={{marginBottom: '8px'}}>
                <Typography>{t("common.From")}</Typography>
              </Box>
              <Controller
                name="start_date"
                control={manualShareSettingForm.control}
                render={({ field, fieldState }) =>
                  <DatePicker
                    disableFuture
                    value={field.value || null}
                    inputFormat={"dd-MM-yyyy"}
                    onChange={(newValue: any) => {
                      if (newValue > manualShareSettingForm.getValues("end_date")) {
                        SnackbarUtil.error(t("export.Start date cannot greater than end date"), {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
                        return;
                      }
                      console.log("Test value--->", newValue)
                      field.onChange(newValue)
                    }}
                    renderInput={(params) =>
                      <TextField
                        sx={{ width: '420px' }}
                        {...params}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message || ""}
                      />}
                  />
                }
              />
            </Grid>
          </Grid>
          <Grid container sx={{marginTop: '16px'}}>
            <Grid lg={8} md={8} sm={8} item>
              <Box sx={{marginBottom: '8px'}}>
                <Typography>{t("common.To")}</Typography>
              </Box>
              <Controller
                name="end_date"
                control={manualShareSettingForm.control}
                render={({ field, fieldState }) =>
                  <DatePicker
                    disableFuture
                    value={field.value || null}
                    inputFormat={"dd-MM-yyyy"}
                    onChange={(newValue: any) => {
                      if (newValue < manualShareSettingForm.getValues("start_date")) {
                        SnackbarUtil.error(t("export.End date cannot less than start date"), {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
                        return;
                      }
                      field.onChange(newValue)
                    }}
                    renderInput={(params) =>
                      <TextField
                        sx={{ width: '420px' }}
                        {...params}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message || ""}
                      />}
                  />
                }
              />
            </Grid>
          </Grid>
          <Grid sx={{marginTop: '8px', width: '100%'}}>
            <Box padding={"8px 0"}>
              <Typography sx={{color: "#999999"}}>{t("export.Email List")}</Typography>
            </Box>
            <ControlledTextFiled
              hookForm={manualShareSettingForm}
              sx={{width: '420px'}}
              name="share"
              multiline
              minRows={5}
              maxRows={5}
              type="text"
            />
            <Grid sx={{display: 'flex', justifyContent: 'start', marginTop: '16px'}}>
              <SaveButton
                disableElevation
                type="submit"
                children= {t("common.confirm")}
                onClick={manualShareSettingForm.handleSubmit(onSubmit)}
                />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
}

export default ManualShare;


import request from "../../../utils/Request";
import { ATTEndpoints } from "../endpoints";
import qs from "qs";

const download_data = async (params: string, data: any) => {
  const url = `${ATTEndpoints.ATT_TRANSACTION_DOWNLOAD_URI}?${params}`
  const response = await request.authorizedAxios().post(url, data, {responseType: "blob"})
  return response;
};


const TransactionService = {
  download_data
}

export default TransactionService;

import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Grid, Drawer } from "@mui/material";
import { useTranslation } from "react-i18next";
import ManualShare from "../../Timecard/components/Timecard/components/ManualShare"
import AutoShare from "../../Timecard/components/Timecard/components/AutoShare"

const AttendanceReportShare = (props: any, ref: any) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false)
  const onClose = () => {
    setOpen(false)
  }
  useImperativeHandle(ref, () => ({
    showReport: async (id:string) => {
      setOpen(true)
    }
  }));
  return (
    <Drawer
    anchor='right'
    open={open}
    onClose={onClose}
  >
    <Grid sx={{
      width: '600px',
      display:'flex',
      flexDirection:'column',
      justifyContent: 'flex-start',
      position: 'relative',
    }}>
      <Grid sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '56px',
        padding: '0px 24px',
        borderBottom: '1px solid #F2F5F8',
      }}>
        <Grid>{ t("export.Report Export") }</Grid>
        <Grid>
          <i style={{ cursor: "pointer" }} className="icon iconfont icon-ic_close" onClick={onClose}></i>
        </Grid>
      </Grid>
      <Grid>
        <ManualShare report_type={"attendance"}/>
        <AutoShare report_type={"attendance"}/>
      </Grid>
    </Grid>
  </Drawer>
  );
}

export default forwardRef(AttendanceReportShare);


import React from "react";
import { IconButton } from '@mui/material';
import { Box, Stack, Tooltip, CircularProgress } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import Dialog from "@/components/dialog"
import CustomDataGrid, { DataGridRef } from "@/components/data-grid/CustomDataGrid";
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import SnackbarUtil from '@/utils/SnackbarUtil';
import TranslationUtil from "@/utils/TranslationUtil";
import { ReactComponent as VisibleLightFaceIcon } from '@/assets/images/credential/VisibleLightFaceIcon.svg';
import { ReactComponent as CardIcon } from '@/assets/images/credential/CardIcon.svg';
import { ReactComponent as FingerPrintIcon } from '@/assets/images/credential/FingerPrintIcon.svg';
import { ReactComponent as PalmIcon } from '@/assets/images/credential/PalmIcon.svg';
import { ReactComponent as FinveinIcon } from '@/assets/images/credential/FinveinIcon.svg';
import { ReactComponent as PinIcon } from '@/assets/images/credential/PinIcon.svg';
import { ReactComponent as DeleteIcon } from '@/assets/images/DeleteIcon.svg';
import DeviceUploadDrawer from "./components/DeviceUploadDrawer";
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import { useTranslation } from "react-i18next";
import { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { usePopupState } from "material-ui-popup-state/hooks";
import CredentialService from "@/services/cms/CredentialService"

enum registrationType {"Common", "fingerprint", "face", "voiceprint", "iris", "retina", "palmprint", "fingervein", "palm", "Visible Light Face"}

enum registrationType {
  "Password" = 102,
  "Card" = 100
}

export const DeviceUploadButton: React.FC = () => {
  const { t } = useTranslation();
  const popupState = usePopupState({ variant: 'popover', popupId: 'DeviceUploadDrawer' })
  const popupStateTrigger = bindTrigger(popupState)
  return (
    <Box>
      <Tooltip title={t("hr.employee.Get credentials")}>
        <IconButton sx={{ borderRadius: '6px', backgroundColor: "rgba(0, 0, 0, 0.04)" }} {...bindTrigger(popupState)}>
          <CloudSyncIcon />
        </IconButton>
      </Tooltip>
      <DeviceUploadDrawer
        {...bindMenu(popupState)}
      />
    </Box>
  );
}

const CredentialsManagement: React.FC = () => {
  const { t } = useTranslation();
  const dataGridRef = React.useRef<DataGridRef>();
  const [loading, setLoading] = React.useState(false);

  const RegistrationRender = (params: GridRenderCellParams) => {
    const { t } = useTranslation();
    const deleteCredential = (row: any, credType: number|string) => {
      if (row.role && row.role === "ADMINSTRATOR"){
        SnackbarUtil.error(t("Please change role to Normal User to delete the Credential"), { anchorOrigin: { vertical: 'top', horizontal: 'center' }})
        return false
      }
      const cred_name = typeof(credType)==="number" ? registrationType[credType] : credType
      Dialog.confirm({
        title: TranslationUtil.t("common.Are you sure?"),
        content: `You want to delete this person '${row.firstName}' ${cred_name} credential permanently?`,
        onConfirm: () => {
          setLoading(true)
          CredentialService.deleteCredsForDevAndCloud(row.personId, {"data":{"credType": credType}}).then(
            (result) => {
              if (result.status<400){
                SnackbarUtil.success(t("common.success"))
                dataGridRef.current?.refresh();}
            }).finally(() => {
              setLoading(false);
            });
        },
      });
    }

    return (
      <Stack direction={"row"} spacing={5} sx={{ marginRight: '10px' }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Tooltip title={t("common.Password")}>
            <PinIcon />
          </Tooltip>
          <Box sx={{ fontSize: "18px", fontWeight: "500", padding: "8px", paddingLeft: "16px" }}>{params.row.credentialCount.passcode}</Box>
          <Tooltip title={t("common.Delete Password")}>
            <IconButton onClick={() => { deleteCredential(params.row, registrationType.Password) }}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Tooltip title={t("common.card")}>
            <CardIcon />
          </Tooltip>
          <Box sx={{ fontSize: "18px", fontWeight: "500", padding: "8px", paddingLeft: "16px" }}>{params.row.credentialCount.card}</Box>
          <Tooltip title={t("common.Delete Card")}>
            <IconButton onClick={() => { deleteCredential(params.row, 100) }}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Tooltip title={t("common.FingerPrint")}>
            <FingerPrintIcon />
          </Tooltip>
          <Box sx={{ fontSize: "18px", fontWeight: "500", padding: "8px", paddingLeft: "16px" }}>{params.row.credentialCount.fingerPrint}</Box>
          <Tooltip title={t("common.Delete FingerPrint")}>
            <IconButton onClick={() => { deleteCredential(params.row, 1) }}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Tooltip title={t("common.Visible Light Face")}>
            <VisibleLightFaceIcon />
          </Tooltip>
          <Box sx={{ fontSize: "18px", fontWeight: "500", padding: "8px", paddingLeft: "16px" }}>{params.row.credentialCount.visibleLightFace}</Box>
          <Tooltip title={t("common.Delete Visible Light Face")}>
            <IconButton onClick={() => { deleteCredential(params.row, 9) }}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>

        {/* <Box sx={{ display: "flex", alignItems: "center" }}>
          <Tooltip title={t("common.Palm")}>
            <PalmIcon />
          </Tooltip>
          <Box sx={{ fontSize: "18px", fontWeight: "500", padding: "8px", paddingLeft: "16px" }}>{params.row.credentialCount.palm}</Box>
          <Tooltip title={t("common.Delete Palm")}>
            <IconButton onClick={() => { deleteCredential(params.row, 8) }}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box> */}

        {/* <Box sx={{ display: "flex", alignItems: "center" }}>
          <Tooltip title={t("common.FingerVein")}>
            <FinveinIcon />
          </Tooltip>
          <Box sx={{ fontSize: "18px", fontWeight: "500", padding: "8px", paddingLeft: "16px" }}>{params.row.credentialCount.fingerVein}</Box>
          <Tooltip title={t("common.Delete FingerVein")}>
            <IconButton onClick={() => { deleteCredential(params.row, 7) }}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box> */}
      </Stack>
    )
  }

  const columns: GridColDef[] = [
    {
      field: 'name', headerName: t("common.Person Name"), sortable: false, minWidth: 160, flex: 1.5, renderCell: (e: GridRenderCellParams) => (
        <span style={{ display: "flex", alignItems: "center" }}>
          {e.row.firstName} {e.row.lastName}
        </span>
      )
    },
    { field: 'personCode', headerName: t("common.Person ID"), flex: 1, type: "string", minWidth: 120, },
    { field: 'role', headerName: t("common.Person Role"), flex: 1, type: "string", minWidth: 120, valueGetter:
      (cell) => cell.row.role === 'ADMINSTRATOR' ? t('dms.Device role Administrator'): t('dms.Device role CommonUser')},
    { field: 'registration', headerName: t("common.Person credentials in the cloud"), minWidth: 300, flex: 4, renderCell: RegistrationRender },
  ]

  return (
    <>
      <CustomBreadcrumbs record={[
        { label: t("common.Credentials Management") },
      ]} />
      <CircularProgress sx={{ position: "absolute", left: "50%", top: "50%", display: loading?"block":"none", zIndex: 1}}/>
      <CustomDataGrid
        uri="/cms/api/v1.0/credentials/person_credentials_report/"
        ref={dataGridRef}
        columns={columns}
        checkboxSelection={false}
        getRowId={(row) => row.personId}
        toolbar={{
          title: " ",
          searchTips: t("common.Search by Person ID, Person Name"),
          filter: false,
          // extraComponentOnLeft: <DeviceUploadButton />
        }}
      />
    </>
  )
}
export default CredentialsManagement;

import React, {useEffect, useRef, useState} from "react";
import {
  Box,
  Breadcrumbs,
  Grid,
  Link,
  Stack,
  Typography,
  Accordion,
  AccordionDetails, TextField, Select, MenuItem
} from "@mui/material";
import TimesheetSetting from "./TimsheetSetting";
import {object, TypeOf, z} from "zod";
import {Controller, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import PatternSetting, {PatternSettingFields} from "./PatternSetting";
import BreakTimeSetting, {BreakTimeSettingFields} from "./BreakTimeSetting";
import TimesheetService from "../../../../services/att/services/TimesheetService";
import {useNavigate, useLocation} from "react-router-dom"
import WebURL from "../../../../urls";
import {useSnackbar} from "notistack";
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import IconButton from '@mui/material/IconButton';
import GenericService from "@/services/GenericService";
import {ATTEndpoints} from "@/services/att/endpoints";
import {useTranslation} from "react-i18next";
import KnowAboutThisPolicy from "./KnowAboutThis"
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {CancelButton, SaveButton, SavingButton} from "@/components/data-grid/CustomButton";
import NGTextField from "@/components/ngteco-form/NGTextField";
import NGInputLabel from "@/components/ngteco-form/NGInputLabel";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import {TimePicker} from "@mui/x-date-pickers";
import Util from "@/utils/Util";
import {forEach} from "lodash";
import {validateTime} from "@mui/x-date-pickers/internals/hooks/validation/useTimeValidation";
import BackGo from "@/components/back-go/BackGo";
/**
 * Validators */


const NewTimesheet: React.FC = () => {
  const knowAboutThisPolicyRef = useRef<any>({})
  const {t} = useTranslation();
  const timesheetSchema = object({
    name: z.string().min(1, t("common.This field is required!"))
      .max(30, t("common.The length of field cannot exceed digits", {number: 30})),
    description: z.string().max(200, t("common.The length of field cannot exceed digits", {number: 200})),
  })
  type TimesheetInput = TypeOf<typeof timesheetSchema>;
  const location = useLocation()
  const state = location.state as { id: string , schedule_used: number}
  const {enqueueSnackbar} = useSnackbar();
  const navigate = useNavigate();
  const patternRef = useRef<{ pattern: any }>();
  const breakTimeRef = useRef<{ breakTime: any }>();
  const sheetRef = useRef<{ sheet: any }>();
  const title = state?.schedule_used == 0 ? t("common.Edit TimeSheet") : state?.id ? t("common.View TimeSheet") : t("common.Add TimeSheet");
  const [timesheet] = useState<any[]>([]);
  const [pattern] = useState<PatternSettingFields>({
    day_change: "00:00",
    punch_interval: 1, max_hrs: 10, pairing: 'first_last',
    overtime_remind: 0, required_record: "any",
  });
  const [breakTime] = useState<BreakTimeSettingFields>({
    break_times_flag: 100, break_switch: false,
    break_start_1: new Date('1990-01-01T12:00:00'), break_end_1: new Date('1990-01-01T12:30:00'),
    pairing_rule_1: "auto_deducted",
    break_start_2: new Date('1990-01-01T13:00:00'), break_end_2: new Date('1990-01-01T13:30:00'),
    pairing_rule_2: "auto_deducted",
    break_start_3: new Date('1990-01-01T14:00:00'), break_end_3: new Date('1990-01-01T14:30:00'),
    pairing_rule_3: "auto_deducted",
  });
  const [periodCycle] = useState<number>(1);
  const [break_loading, setBreakLoading] = React.useState(true);
  const [overtime_loading, setOvertimeLoading] = React.useState(false);
  const [option, setOption] = React.useState('clocking');

  function handleClick() {
    setBreakLoading(true);
  }

  const {
    register,
    trigger,
    setValue,
    handleSubmit,
    formState: {errors},
    getValues
  } = useForm<TimesheetInput>({
    resolver: zodResolver(timesheetSchema),
  });
  useEffect(() => {
    if (state?.id) {
      GenericService.retrieve(ATTEndpoints.ATT_TIMESHEET_URI, `${state.id}`).then((res) => {
        setValue("name", res.data?.name);
        setValue("description", res.data?.description);
        setOvertimeLoading(res.data?.pattern.overtime_flag);
        setOption(res.data?.pattern.option)
        if (res.data?.pattern.break_pairs.length === 0) {
          setBreakLoading(false)
        }
      })
    }
  }, []);
  const scrollTo = (name: string) => {
    const anchorElement = document.getElementsByName(name)[0]
    if (anchorElement) {
      anchorElement.scrollIntoView({behavior: "smooth"})
    }
  }
  const onSubmit = async (formData: any) => {
    let result;
    const sheetData = await sheetRef.current?.sheet();
    const patternData = await patternRef.current?.pattern();
    const punch_interval = patternData?.punch_interval
    if (patternData.option === 'clocking') {
      let max_punch_interval = 999;
      sheetData?.sheet.map((sheet_obj: any) => {
        const minuteDiff = Util.timeFn(Util.time2datetime(sheet_obj.check_in_start),
          Util.time2datetime(sheet_obj.check_out_end))
        if (minuteDiff < max_punch_interval) {
          max_punch_interval = minuteDiff
        }
      })
      if (punch_interval > max_punch_interval) {
        enqueueSnackbar(t("att.timeSheet.The Punch Interval cannot exceed the check-out and check-in interval"), {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        });
        return
      }
    }
    const breakTimeData = await breakTimeRef.current?.breakTime();
    // patternData.check_in = patternData.check_in.toString().slice(16, 21)
    // patternData.check_out = patternData.check_out.toString().slice(16, 21)
    // patternData.day_change = patternData.day_change.toString().slice(16, 21)
    const break_pairs = []
    if (break_loading) {
      breakTimeData.break_start_1 = breakTimeData.break_start_1.toString().slice(16, 21)
      breakTimeData.break_end_1 = breakTimeData.break_end_1.toString().slice(16, 21)
      breakTimeData.break_start_2 = breakTimeData.break_start_2.toString().slice(16, 21)
      breakTimeData.break_end_2 = breakTimeData.break_end_2.toString().slice(16, 21)
      breakTimeData.break_start_3 = breakTimeData.break_start_3.toString().slice(16, 21)
      breakTimeData.break_end_3 = breakTimeData.break_end_3.toString().slice(16, 21)
      breakTimeData.break_switch = break_loading
      break_pairs.push({
        "start": breakTimeData.break_start_1,
        "end": breakTimeData.break_end_1,
        "pairing_rule": breakTimeData.pairing_rule_1
      })
      if (breakTimeData.break_times_flag.toString().charAt(1) === "1") {
        break_pairs.push({
          "start": breakTimeData.break_start_2,
          "end": breakTimeData.break_end_2,
          "pairing_rule": breakTimeData.pairing_rule_2
        })
      }
      if (breakTimeData.break_times_flag.toString().charAt(2) === "1") {
        break_pairs.push({
          "start": breakTimeData.break_start_3,
          "end": breakTimeData.break_end_3,
          "pairing_rule": breakTimeData.pairing_rule_3
        })
      }
    }
    patternData["break_pairs"] = break_pairs
    patternData["max_hrs"] = parseInt(patternData.max_hrs)
    patternData["overtime_flag"] = overtime_loading
    const mix_patternData = {...sheetData, ...patternData}
    delete mix_patternData.sheet
    if (sheetData && patternData) {
      const data = {
        ...formData,
        ...sheetData,
        pattern: mix_patternData
      };
      if (state?.id) {
        result = await TimesheetService.updateTimesheet(state.id, data);
        if (result.status < 400) {
          enqueueSnackbar("Timesheet updated successfully.", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          });
          navigate(WebURL.ATT_TIMESHEET);
        }
      } else if (sheetData.cycle < 1 || sheetData.cycle > 12 || isNaN(sheetData.cycle) ||
        String(sheetData.cycle).indexOf(".") > -1) {
        scrollTo("cycle")
      } else {
        result = await TimesheetService.addTimesheet(data);
        if (result.status < 400) {
          enqueueSnackbar("Time sheet created successfully.", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          });
          navigate(WebURL.ATT_TIMESHEET);
        }
      }
    }
  };
  return (
    <Box sx={{width: '100%', paddingRight: '24px', boxSizing: 'border-box', marginBottom: '12px', height: 'auto' }}>
      <Grid sx={{width: '100%' }}>
        <BackGo title={title}></BackGo>
        <CustomBreadcrumbs record={[
          // {path: WebURL.ATT_TIMESHEET, label: t("common.TimeSheet")},
          {
            label: title,
            helpInfo: t("att.timeSheet.Title Tip")
          },
        ]}/>
      </Grid>
      <Grid container={true} rowSpacing={1} sx={{margin: "0", backgroundColor: '#ffffff', borderRadius: '6px', marginTop: '12px', minHeight: 'calc(100vh - 155px)'}}>
        <Grid item xs={12} sx={{p: "0 16px"}}>
          <Grid container={true} rowSpacing={2} columnSpacing={6} component={"form"} sx={{marginTop: '2px'}}
                onSubmit={handleSubmit(onSubmit)}>
            <Grid item={true} xs={4}>
              <NGTextField
                fullWidth={true}
                placeholder={t("common.Timesheet Name")}
                margin={"normal"}
                label={t("common.Timesheet Name")}
                required
                InputLabelProps={{
                  shrink: true
                }}
                error={!!errors['name']}
                disabled={ state.schedule_used!=0}
                helperText={errors['name']?.message || ""}
                {...register("name")}
              />
            </Grid>
            <Grid item={true} xs={4}>
              <NGTextField
                fullWidth={true}
                placeholder={t("common.Timesheet Description")}
                label={t("common.Timesheet Description")}
                InputLabelProps={{
                  shrink: true
                }}
                disabled={state.schedule_used!=0}
                margin={"normal"}
                error={!!errors['description']}
                helperText={errors['description']?.message || ""}
                {...register("description")}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid sx={{marginLeft: "15px"}} item xs={12}>
          <NGInputLabel label={t("common.Schedule Rule")} style={{fontWeight: 'bold', fontSize: '16px'}}/>
        </Grid>
        <Grid item xs={12}>
          <Grid container={true} component={"form"}>
            <TimesheetSetting ref={sheetRef} cycle={periodCycle} period={"week"} timesheet={timesheet}
                              view_data={state?.id} schedule_used={state?.schedule_used} setOption={setOption}/>
          </Grid>
        </Grid>
        <Grid sx={{marginLeft: "15px"}} item xs={12}>
          <NGInputLabel label={t("common.ATT records statistics rule")} style={{fontWeight: 'bold', fontSize: '16px'}}/>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{
            borderRadius: '5px',
            background: '#fff',
          }}>
            <Grid sx={{padding: "12px"}} container={true} component={"form"}>
              <PatternSetting ref={patternRef} pattern={pattern} view_data={state?.id}
                              schedule_used={state?.schedule_used} option={option}/>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{
            borderRadius: '5px',
            background: '#fff',
          }}>
            <Grid sx={{padding: "16px"}} container={true} component={"form"} >
              <Grid container={true} component={"form"} item xs={6}>
                <Grid container={true} xs={3}>
                  <NGInputLabel label={t("att.timeSheet.Break Time")}/>
                  <Switch
                    sx={{marginTop: "-6px"}}
                    checked={break_loading}
                    disabled={state.schedule_used!=0}
                    onChange={() => setBreakLoading(!break_loading)}
                    name="Break Time"
                    color="primary"
                  />
                </Grid>
                <Grid container={true} xs={3}>
                  <NGInputLabel label={t("att.timeSheet.Overtime")}/>
                  <Switch
                    sx={{marginTop: "-6px"}}
                    checked={overtime_loading}
                    disabled={state.schedule_used!=0}
                    onChange={() => setOvertimeLoading(!overtime_loading)}
                    name="overtime"
                    color="primary"
                  />
                </Grid>
              </Grid>
              <Grid columnSpacing={12} >
                {break_loading ? <BreakTimeSetting ref={breakTimeRef} break_time={breakTime}
                                                   schedule_used={state?.schedule_used} view_data={state?.id} /> :
                  <Grid></Grid>}
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} sx={{p: "0 8px", display: 'flex', justifyContent: 'start', marginBottom: '12px'}}>
          <Box sx={{display: "inline-flex"}}>
            <Link
              underline={"none"}
              onClick={() => {
                knowAboutThisPolicyRef?.current?.showTips()
              }}
              sx={{
                cursor: "pointer",
                padding: "0 8px",
              }}
            >
              <Typography>{t("organ.know more about this?")}</Typography>
            </Link>
          </Box>
        </Grid>
        <Grid item xs={12} sx={{
          p: "0 16px", borderTop: '1px solid #F2F5F8', marginBottom: "12px",
          marginTop: '50px', display: 'flex', justifyContent: 'start', minHeight: "52px"
        }}>
          {state.schedule_used == 0 &&
            <Stack spacing={3} direction={"row"} justifyContent="flex-end" sx={{marginTop: '4px'}}>
              <CancelButton/>
              <SaveButton type={'submit'} disabled={state.schedule_used != 0} onClick={async () => {
                const isValid = await trigger();
                if (isValid) {
                  const data = getValues();
                  await onSubmit(data);
                } else {
                  scrollTo("name")
                }
              }}/>
            </Stack>
          }
        </Grid>
      </Grid>
      <KnowAboutThisPolicy ref={knowAboutThisPolicyRef}></KnowAboutThisPolicy>
    </Box>
  )
}
export default NewTimesheet

import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Grid,
  IconButton,
  Breadcrumbs,
  Typography,
  Badge,
  Paper,
  Checkbox,
  Tooltip,
  Drawer,
} from "@mui/material";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import GenericService from "@/services/GenericService";
import { styled } from "@mui/material/styles";
import SnackbarUtil from "@/utils/SnackbarUtil";
import { DMSEndpoints } from "@/services/dms/endpoints";
import DeviceService from "@/services/dms/DeviceService";
import { LoadingButton } from "@mui/lab";
import { CancelButton, SaveButton } from "@/components/data-grid/CustomButton";
import { useTranslation } from "react-i18next";
import { Add } from "@mui/icons-material";
import CustomDataGrid from "@/components/data-grid/CustomDataGrid";
import { HREndpoints } from "@/services/hr/endpoints";
import { GridColDef, GridSelectionModel } from "@mui/x-data-grid";
import ActionButton from "@/components/actions/ActionButton";
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  height: 70,
  lineHeight: "20px",
}));

const customDataGridStyle = {
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "#F0F1F4!important",
    color: "#304669!important",
  },
  ".MuiDataGrid-columnHeader:focus-within": { outline: "none" },
  ".MuiDataGrid-cell": { borderBottom: "0px", color: "#304669" },
  ".MuiDataGrid-cell:focus-within": {
    outline: "none!important",
    color: "#2BA9DE!important",
  },
  ".MuiDataGrid-columnSeparator": { display: "none !important" },
  ".MuiSvgIcon-root": { backgroundColor: "transparent" },
  ".MuiDataGrid-root": { padding: "8px", borderRadius: "4px" },
  ".MuiDataGrid-row": {
    borderBottom: "1px solid #E7EBF0",
    maxHeight: "43px!important",
    minHeight: "43px!important",
  },
  ".MuiCheckbox-root": { color: "#D1D1D1" },
  ".MuiDataGrid-cellContent": {
    color: "#304669!important",
    fontFamily: "Roboto",
    letterSpacing: "0.4px",
    fontSize: "14px",
  },
  ".MuiDataGrid-virtualScrollerRenderZone": { backgroundColor: "#FFFF" },
  ".MuiDataGrid-footerContainer": {
    display: "block",
    width: "100%",
    border: "0px",
  },
  ".MuiTablePagination-spacer": { display: "none" },
  ".MuiTablePagination-actions": {
    position: "absolute",
    right: "0px",
    backgroundColor: "#FFF",
  },
  ".MuiTablePagination-displayedRows": { position: "absolute", right: "90px" },
  ".MuiDataGrid-virtualScroller": { overflow: "auto !important" },
  ".MuiTablePagination-select": {
    backgroundColor: "#F0F1F4",
    borderRadius: "4px!important",
  },
  ".MuiTablePagination-toolbar": { paddingLeft: "0px" },
  ".MuiIconButton-root.Mui-disabled": {
    backgroundColor: "#F0F1F4",
    borderRadius: "0px",
  },
  // '.MuiIconButton-root': {borderRadius: '0px'},
  ".Mui-checked": { color: "rgb(54, 201, 109)!important" },
};

export const EmployeeListButtonDrawer: React.FC = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const state = location.state as { id: string };
  const navigate = useNavigate();
  const [visible, setVisible] = React.useState(false);
  const [gridData, setGridData] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [selectionData, setSelectionData] = useState<Record<string, any>>([]);
  const [filterParams, setFilterParams] = useState<Record<any, any>>({});
  const [userCount, setUserCount] = useState<number>(0);
  const [maxUserCount, setMaxUserCount] = useState<number>(500);
  const [deviceStatus, setDeviceStatus] = useState<string>("0");
  const columns: GridColDef[] = [
    {
      field: "employeeCode",
      headerName: t("common.Person Id"),
      type: "string",
    },
    { field: "firstName", headerName: t("common.First Name") },
    { field: "lastName", headerName: t("common.Last Name") },
  ];

  const handleDataGridSave = (value: any) => {
    if (value) {
      setGridData(value);
    }
  };
  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      setVisible(open);
    };
  useEffect(() => {
    DeviceService.deviceInfo(state.id).then((res: any) => {
      const data = res.data
      const params = data.parameters
      setDeviceStatus(data.status)
      setMaxUserCount(parseInt(params?.maxUserCount))
      setUserCount(parseInt(params?.userCount))
    })
  }, [selectionModel])

  return (
    <div>
      <React.Fragment>
        <Grid onClick={toggleDrawer(true)}>
          {deviceStatus === '1' &&
            <ActionButton
              icon="icon-ic_add"
              title={t("common.add")}
            ></ActionButton>}
        </Grid>

        <Drawer anchor="right" open={visible} onClose={toggleDrawer(false)}>
          <Grid
            sx={{
              minWidth: "400px",
              height: "100vh",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Grid
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                height: "56px",
                flexShrink: "0",
                padding: "0px 24px",
                borderBottom: "1px solid #F2F5F8",
              }}
            >
              <Grid>{t("common.Select person")}</Grid>
              <Grid sx={{
                cursor:'pointer'
              }}
              onClick={toggleDrawer(false)}>
                <i className="icon iconfont icon-ic_close"></i>
              </Grid>
            </Grid>
            <Grid
              sx={{
                padding: "0px 24px",
                flexGrow: "1",
                display: "flex",
              }}
            >
              <Grid
                sx={{
                  marginTop: "20px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <CustomDataGrid
                  containerStyle={{
                    paddingRight: "0px",
                  }}
                  height="calc(100vh - 160px)"
                  tableStyle={{
                    padding: "0px",
                  }}
                  uri={`${HREndpoints.HR_EMPLOYEE_NOT_IN_DEVICE}${
                    state.id || ""
                  }`}
                  columns={columns}
                  checkboxSelection={true}
                  rowsPerPageOptions={[10, 20, 50, 100]}
                  sx={customDataGridStyle}
                  onDataChange={handleDataGridSave}
                  onSelectionModelChange={(ids) => {
                    setSelectionModel(ids);
                    setSelectionData(
                      ids.map((id) =>
                        gridData.find((row: any) => row.id === id)
                      )
                    );
                  }}
                  selectionModel={selectionModel}
                  requestParams={filterParams}
                  toolbar={{
                    title: "",
                    filter: false,
                    refresh: true,
                    searchTips: t("common.Search by Person First Name"),
                  }}
                />

                <Grid
                  sx={{
                    margin: "16px 0px",
                  }}
                >
                  <CancelButton
                    sx={{
                      padding: "10px",
                      textTransform: "none",
                      minWidth:'120px'
                    }}
                    onClick={() => {
                      setVisible(false);
                    }}
                  />
                  <SaveButton
                    children={t("common.confirm")}
                    sx={{
                      padding: "10px",
                      textTransform: "none",
                      marginLeft: "10px",
                      minWidth:'120px'
                    }}
                    onClick={() => {
                      if (selectionModel && selectionModel.length === 0) {
                        SnackbarUtil.error(t("dms.No Person Record Selected"), {
                          anchorOrigin: {
                            vertical: "top",
                            horizontal: "center",
                          },
                        });
                        return;
                      }
                      if (userCount + selectionModel.length > maxUserCount) {
                        SnackbarUtil.error(t("dms.The device has insufficient user capacity!"), {
                          anchorOrigin: {
                            vertical: "top",
                            horizontal: "center",
                          },
                        });
                        return;
                      }
                      setLoading(true);
                      DeviceService.addPerson(state.id || "", selectionModel)
                        .then((result) => {
                          console.log("DeviceService.addPerson: ", result);
                          SnackbarUtil.success(
                            t("dms.People synced to the device successfully", {
                              No: selectionModel.length,
                            })
                          );
                          navigate(-1);
                        })
                        .catch((error) => {
                          if (error.response?.status < 500) {
                            // do something when status is 4xx.
                            console.log("DeviceService.addPerson: ", error);
                          }
                        })
                        .finally(() => {
                          setLoading(false);
                        });
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Drawer>

        {/* <SwipeableDrawer
          anchor={'right'}
          open={visible}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          <Box sx={{padding: '10px', height: '100%', width: '600px'}}>
            <Box sx={{height: 'calc(100% - 60px)', width: '95%'}}>
              <Grid className={"toolbar-container"}>
                <Box sx={{display: 'flex',}}>
                  <Breadcrumbs separator="" sx={{height: "32px", p: '8px'}}>
                    <Typography>{t("common.Select person")}</Typography>
                  </Breadcrumbs>
                </Box>
              </Grid>
              <Box sx={{padding: "10px 0px"}}>
                <CustomDataGrid
                  uri={`${HREndpoints.HR_EMPLOYEE_NOT_IN_DEVICE}${state.id || ""}`}
                  columns={columns}
                  checkboxSelection={true}
                  sx={customDataGridStyle}
                  onDataChange={handleDataGridSave}
                  onSelectionModelChange={(ids) => {
                    setSelectionModel(ids);
                    setSelectionData(ids.map((id) => gridData.find((row: any) => row.id === id)))
                  }}
                  selectionModel={selectionModel}
                  requestParams={filterParams}
                  toolbar={{
                    title: "",
                    filter: false,
                    refresh: true,
                    searchTips: t("common.Search by Person First Name"),
                  }}
                />
                <Box>
                  <CancelButton
                    sx={{width: '48%', padding: "10px", textTransform: "none"}}
                    onClick={() => {
                      setVisible(false)
                    }}/>
                  <SaveButton
                    children={t("common.confirm")}
                    sx={{width: '48%', padding: "10px", textTransform: "none", marginLeft: "10px"}}
                    onClick={() => {
                      if (selectionModel && selectionModel.length === 0) {
                        SnackbarUtil.error(t("dms.No Person Record Selected"), {
                          anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center'
                          }
                        })
                        return
                      }
                      setLoading(true)
                      DeviceService.addPerson(state.id || '', selectionModel).then(
                        (result) => {
                          console.log('DeviceService.addPerson: ', result)
                          SnackbarUtil.success(t('dms.People synced to the device successfully', {No: selectionModel.length}))
                          navigate(-1)
                        }).catch((error) => {
                        if (error.response?.status < 500) {
                          // do something when status is 4xx.
                          console.log('DeviceService.addPerson: ', error)
                        }
                      }).finally(() => {
                        setLoading(false);
                      });
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </SwipeableDrawer> */}
      </React.Fragment>
    </div>
  );
};

import React, {useEffect, useRef, useState} from "react";
import {ATTEndpoints} from "../../../../../services/att/endpoints";
import CustomDataGrid, {DataGridRef} from "../../../../../components/data-grid/CustomDataGrid";
import {
  GridColDef,
  GridFilterInputValueProps,
  GridFilterItem,
  GridFilterModel,
  GridFilterOperator,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import {Box, Grid, Stack, TextField,} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers";
import {useTranslation} from "react-i18next";
import WebURL from "@/urls";
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import SnackbarUtil from "@/utils/SnackbarUtil";
import CustomRowButton from "@/components/data-grid/CustomRowButton";
import {ReactComponent as IconEdit} from "@/assets/icons/IconEdit.svg";
import {ReactComponent as IconDeletes} from "@/assets/icons/IconDeletes.svg";
import Dialog from "@/components/dialog";
import DepartmentService from "@/services/hr/DepartmentService";
import PunchView from "@/pages/att/Timecard/components/ManualLog/components/ViewPunch";
import {useNavigate} from "react-router-dom";
import PunchRecordService from "@/services/att/services/PunchRecordService";

const SUBMIT_FILTER_STROKE_TIME = 500;

function InputNumberInterval(props: GridFilterInputValueProps) {
  const {t} = useTranslation();
  const {item, applyValue} = props;

  const filterTimeout = useRef<any>();
  const [filterValueState, setFilterValueState] = useState<[Date, Date]>([item.value[0], item.value[1]]);

  useEffect(() => {
    return () => {
      clearTimeout(filterTimeout.current);
    };
  }, []);

  const updateFilterValue = (lowerBound: Date, upperBounding: Date) => {
    clearTimeout(filterTimeout.current);
    setFilterValueState([lowerBound, upperBounding]);

    filterTimeout.current = setTimeout(() => {
      applyValue({...item, value: [lowerBound, upperBounding]});
    }, SUBMIT_FILTER_STROKE_TIME)
  };

  const handleUpperFilterChange = (event: any) => {
    const newUpperBound = event;
    updateFilterValue(filterValueState[0], newUpperBound);
  };
  const handleLowerFilterChange = (event: any) => {
    const newLowerBound = event;
    updateFilterValue(newLowerBound, filterValueState[1]);
  };

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'end',
        width: "240px"
      }}
    >
      <DatePicker
        label={t("common.Start Date")}
        value={filterValueState[0] || new Date(new Date().getFullYear(), new Date().getMonth(), 1)}
        maxDate={filterValueState[1]}
        inputFormat={"dd-MM-yyyy"}
        onChange={handleLowerFilterChange}
        renderInput={(params) =>
          <TextField
            variant="standard"
            {...params}
          />}
      />
      <DatePicker
        label={t("common.End Date")}
        value={filterValueState[1]}
        minDate={filterValueState[0]}
        inputFormat={"dd-MM-yyyy"}
        onChange={handleUpperFilterChange}
        renderInput={(params) =>
          <TextField
            variant="standard"
            {...params}
          />}
      />
    </Box>
  )
}

const ManualLogGrid: React.FC = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const today = new Date()
  const firstDay = new Date()
  firstDay.setDate(1)
  const dataGridRef = useRef<DataGridRef>();
  const manualViewRef = useRef<any>({})
  const [filterParams, setFilterParams] = useState<Record<any, any>>({
    date_range: [
      firstDay.toISOString().slice(0, 10),
      today.toISOString().slice(0, 10)
    ]
  });
  const [filterDate, setFilterDate] = useState<GridFilterModel>({
    items: [
      {
        id: 1,
        columnField: 'att_date',
        value: [firstDay.toISOString().slice(0, 10), today.toISOString().slice(0, 10)],
        operatorValue: "between",
      }
    ]
  })

  const punchDateOperators: GridFilterOperator[] = [
    {
      label: t("common.Between"),
      value: "between",
      getApplyFilterFn: (filterItem: GridFilterItem, column) => {
        if (!Array.isArray(filterItem.value) || filterItem.value.length !== 2) {
          return null;
        }
        return ({value}) => {
          return true;
        };
      },
      InputComponent: InputNumberInterval
    },
  ];
  const SourceField = (params: GridRenderCellParams) => {
    let punch_from = "";
    if (params.value == 'manual') {
      punch_from = t("common.manual");
    } else {
      punch_from = params.value
    }
    return <Grid>{punch_from}</Grid>;
  };

  const columns: GridColDef[] = [
    {
      field: 'employee_name', headerName: t("common.Person Name"), minWidth: 200, flex: 0.2, filterable: false,
      renderCell: (params) => {
        return (
          <Grid style={{display: "flex", alignItems: "center"}}>
            {/* <AvatarIcon name={params.row.first_name} /> */}
            <Box style={{padding: '0 8px'}}>{params.row.first_name} {params.row.last_name}</Box>
          </Grid>
        )
      }
    },
    {
      field: 'employee_code',
      headerName: t("common.Person ID"),
      type: "string",
      minWidth: 200,
      flex: 0.2,
      filterable: false
    },
    {
      field: 'att_date', headerName: t("common.Punch Date"), type: "date", minWidth: 130, flex: 0.3,
      filterOperators: punchDateOperators,
    },
    {
      field: 'attendance_status',
      headerName: t("common.Attendance record"),
      flex: 0.3,
      minWidth: 200,
      filterable: false,
    },
    {field: 'timezone', headerName: t("att.schedule.TimeZone"), minWidth: 100, flex: 0.2},
    {field: 'punch_from', headerName: t("att.schedule.punch from"), flex: 0.2, minWidth: 100, renderCell: SourceField,},
    {
      field: 'actions', flex: 0.5, headerName: t("common.action"), headerAlign: 'left', align: 'left',
      renderCell: (e) => {
        return (
          <Stack direction={"row"}>
            <Grid sx={{marginLeft: "-5px"}}>
              <CustomRowButton
                insideSvgIcon={IconEdit}
                actionName={t("common.edit")}
                buttonProps={{
                  key: "edit",
                  onClick: () => {
                    manualViewRef?.current?.showDetail(e.id)
                  }
                }}
              />
            </Grid>
            <Grid>
              <CustomRowButton
                insideSvgIcon={IconDeletes}
                actionName={t("common.delete")}
                buttonProps={{
                  key: "delete",
                  onClick: () => (
                    Dialog.confirm({
                      title: t("common.Delete Punch Record?"),
                      content: t("common.Manual Log Delete Note."),
                      onConfirm: () => {
                        PunchRecordService.destroy((e.id).toString()).then(
                          (result) => {
                            SnackbarUtil.success(t('common.success'));
                            dataGridRef.current?.refresh();
                          });
                      }
                    })
                  )
                }}
              />
            </Grid>
          </Stack>
        )
      }
    }
  ]

  const toolbar = {
    title: t("common.Mend attendance punch"),
    searchTips: t("common.Search by Person ID, Person Name"),
    search: true,
    // info: {setDisplay: setInfo,},
    breadcrumbs: [
      {breadcrumbName: t("common.Mend attendance punch"), path: WebURL.ATT_TIMECARD_MANUAL_LOG}
    ],
    actions: [{
      key: 'add', iconFont: 'icon-ic_add', helpTxt: t("common.add"), callback: async () => {
        navigate(WebURL.ATT_TIMECARD_PUNCH_RECORD_ADD);
      }
    }],
  }
  const reloadTable = () => {
    dataGridRef.current?.refresh();
  }

  return (
    <>
      <Box
        sx={{
          '& .MuiDataGrid-row-height-no-limit': {
            maxHeight: '180px!important'
          },
        }}
      >
        <CustomBreadcrumbs record={[{label: t("common.Mend attendance punch")}]}/>
        <CustomDataGrid
          columns={columns}
          ref={dataGridRef}
          uri={ATTEndpoints.ATT_MANUAL_LOG_URI}
          // checkboxSelection={true}
          requestParams={filterParams}
          filterModel={filterDate}
          onFilterModelChange={(model) => {
            if (typeof (model.items[0].value[0]) == 'object') {
              model.items[0].value[0] = model.items[0].value[0].toISOString().slice(0, 10)
            }
            if (typeof (model.items[0].value[1]) == 'object') {
              model.items[0].value[1] = model.items[0].value[1].toISOString().slice(0, 10)
            }
            setFilterDate(model)
            setFilterParams({
              date_range: [
                model.items[0].value[0],
                model.items[0].value[1]
              ]
            })
            dataGridRef.current?.refresh(true);
          }}
          toolbar={toolbar}
          localeText={{
            filterPanelColumns: t("common.Columns"),
            filterPanelOperators: t("common.Operators"),
          }}
        />
        <PunchView ref={manualViewRef} reloadTable={reloadTable}></PunchView>
      </Box>
    </>
  )
}
export default ManualLogGrid

import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  Typography,
  TextField, Box,
} from "@mui/material";
import {ArrowForwardIosSharp} from "@mui/icons-material";
import {Controller, FormProvider, useForm} from "react-hook-form";
import {date, object, TypeOf, z} from "zod";
import {TimePicker} from "@mui/x-date-pickers";
import Util from "../../../../utils/Util";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import GenericService from "@/services/GenericService";
import {ATTEndpoints} from "@/services/att/endpoints";
import FormControl from "@/pages/auth/Login/components/SignUp";
import {zodResolver} from "@hookform/resolvers/zod";
import { useTranslation } from "react-i18next";
import NGInputLabel from "@/components/ngteco-form/NGInputLabel";
import SnackbarUtil from "@/utils/SnackbarUtil";
import AddBoxIcon from '@mui/icons-material/AddBox';
import RemoveIcon from '@mui/icons-material/Remove';
import {useSnackbar} from "notistack";


const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: '530px',
    backgroundColor:"white",
    border: "solid #2BA9DE 1px",
    color: "gray",
    fontSize: 15,
    lineHeight: 2,
    height: "150px",
    "& .MuiTooltip-arrow": {
        top: "-5px !important",
        "&::before": {
          border: "solid #2BA9DE 1px",
          backgroundColor:"white",
        },},
  },
});

const HelpOutlineOutlinedIconCustom = styled(HelpOutlineOutlinedIcon)`
  &:hover {
    color: #2BA9DE;
  }
`;
const IconButtonCustom = styled(IconButton)`
  &:hover {
    color: #2BA9DE;
  }
`;

  export type BreakTimeSettingFields = {
    break_times_flag: number,
    break_switch: boolean,
    break_start_1: any;
    break_end_1: any;
    pairing_rule_1: string;
    break_start_2?: any;
    break_end_2?: any;
    pairing_rule_2?: string;
    break_start_3?: any;
    break_end_3?: any;
    pairing_rule_3?: string;
  }


type BreakTimeSettingProps = {
    break_time: BreakTimeSettingFields;
    view_data: any;
    schedule_used?: any;
  }

const BreakTimeSetting = (props: BreakTimeSettingProps, ref: any) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const defaultBreakTime = props.break_time;
  const param_id = props.view_data;
  const schedule_used = props.schedule_used;
  const break_times_flag = props.break_time.break_times_flag
  const [breakTimeCount, setBreakTimeCount] = useState<number>(100);
  const patternSchema = object({
    break_start_1: z.date(
          {
            errorMap: (issue, { defaultError }) =>
              ({ message: issue.code === "invalid_date" ? t("common.Invalid time format.") : issue.code === "invalid_type" ? t("common.This field is required!") : defaultError })
          }),
    break_end_1: z.date(
          {
            errorMap: (issue, { defaultError }) =>
              ({ message: issue.code === "invalid_date" ? t("common.Invalid time format.") : issue.code === "invalid_type" ? t("common.This field is required!") : defaultError })
          }),
    pairing_rule_1: z.string(),
    break_start_2: z.date(
          {
            errorMap: (issue, { defaultError }) =>
              ({ message: issue.code === "invalid_date" ? t("common.Invalid time format.") : issue.code === "invalid_type" ? t("common.This field is required!") : defaultError })
          }),
    break_end_2: z.date(
          {
            errorMap: (issue, { defaultError }) =>
              ({ message: issue.code === "invalid_date" ? t("common.Invalid time format.") : issue.code === "invalid_type" ? t("common.This field is required!") : defaultError })
          }),
    pairing_rule_2: z.string(),
    break_start_3: z.date(
          {
            errorMap: (issue, { defaultError }) =>
              ({ message: issue.code === "invalid_date" ? t("common.Invalid time format.") : issue.code === "invalid_type" ? t("common.This field is required!") : defaultError })
          }),
    break_end_3: z.date(
          {
            errorMap: (issue, { defaultError }) =>
              ({ message: issue.code === "invalid_date" ? t("common.Invalid time format.") : issue.code === "invalid_type" ? t("common.This field is required!") : defaultError })
          }),
    pairing_rule_3: z.string(),
    break_times_flag: z.number(),
    break_switch: z.boolean()
  });

  type PatternInput = TypeOf<typeof patternSchema>;


  const BreakTimeSettingForm = useForm<PatternInput>({
    defaultValues: defaultBreakTime,
    resolver: zodResolver(patternSchema)
  })
  const {
    register,
    trigger,
    setError,
    formState: { errors },
  } = BreakTimeSettingForm;
   useEffect(() => {
     if (param_id){
       GenericService.retrieve(ATTEndpoints.ATT_TIMESHEET_URI, `${param_id}`).then((res) => {
         const break_pairs = res.data?.pattern.break_pairs
         let break_pairs_length = 0
         if (break_pairs){
           break_pairs_length = break_pairs.length
         }
         if (break_pairs_length === 1){
            setBreakTimeCount(100)
           BreakTimeSettingForm.setValue("break_start_1", Util.time2datetime(break_pairs[0].start));
           BreakTimeSettingForm.setValue("break_end_1", Util.time2datetime(break_pairs[0].end));
           BreakTimeSettingForm.setValue("pairing_rule_1", break_pairs[0].pairing_rule)
         }
         else if (break_pairs_length === 2){
            setBreakTimeCount(110)
           BreakTimeSettingForm.setValue("break_start_1", Util.time2datetime(break_pairs[0].start));
           BreakTimeSettingForm.setValue("break_end_1", Util.time2datetime(break_pairs[0].end));
           BreakTimeSettingForm.setValue("pairing_rule_1", break_pairs[0].pairing_rule)
           BreakTimeSettingForm.setValue("break_start_2", Util.time2datetime(break_pairs[1].start));
           BreakTimeSettingForm.setValue("break_end_2", Util.time2datetime(break_pairs[1].end));
           BreakTimeSettingForm.setValue("pairing_rule_2", break_pairs[1].pairing_rule)
         }
         else if (break_pairs_length === 3){
            setBreakTimeCount(111)
            BreakTimeSettingForm.setValue("break_start_1", Util.time2datetime(break_pairs[0].start));
            BreakTimeSettingForm.setValue("break_end_1", Util.time2datetime(break_pairs[0].end));
            BreakTimeSettingForm.setValue("pairing_rule_1", break_pairs[0].pairing_rule)
            BreakTimeSettingForm.setValue("break_start_2", Util.time2datetime(break_pairs[1].start));
            BreakTimeSettingForm.setValue("break_end_2", Util.time2datetime(break_pairs[1].end));
            BreakTimeSettingForm.setValue("pairing_rule_2", break_pairs[1].pairing_rule)
            BreakTimeSettingForm.setValue("break_start_3", Util.time2datetime(break_pairs[2].start));
            BreakTimeSettingForm.setValue("break_end_3", Util.time2datetime(break_pairs[2].end));
            BreakTimeSettingForm.setValue("pairing_rule_3", break_pairs[2].pairing_rule)
         }
      })
     }}, [])
  useImperativeHandle(ref, () => ({
    breakTime:async () => {
      const isValid = await trigger();
      BreakTimeSettingForm.setValue("break_times_flag", breakTimeCount);
      return BreakTimeSettingForm.getValues()
    }
  }));
  return (
    <Accordion disableGutters={true} sx={{
      boxShadow: "none",
      border: "none",
      marginBlock: "10px",
      width: "100%",
    }}>
        <FormProvider {...BreakTimeSettingForm}>
        <Grid container={true} rowSpacing={1} columnSpacing={6} component={"form"}>
          <Grid item xs={12} sx={{p: "0 16px"}}>
            <Grid container={true} rowSpacing={1} columnSpacing={3} >
              <Grid item={true} sx={{marginTop:"10px"}}>
                 <NGInputLabel label={t("att.timeSheet.From")}/>
               </Grid>
              <Grid item={true} xs={3}>
                <Controller
                  control={BreakTimeSettingForm.control}
                  name={"break_start_1"}
                  render={({field, fieldState}) =>
                      <TimePicker
                        value={field.value || null}
                        ampm={false}
                        onChange={(newValue:any) => {
                          if (newValue >= BreakTimeSettingForm.getValues("break_end_1")) {
                            SnackbarUtil.error(t("export.Break start time cannot greater than the end time"), {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
                            return;
                          }
                          field.onChange(newValue)
                        }}
                        disabled={schedule_used!=0}
                        renderInput={(fieldProps) => {
                          return <TextField
                            size={"medium"}
                            fullWidth={true}
                            {...fieldProps}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message || ""}
                          />
                        }}
                      />
                  }
                />
              </Grid>
              <Grid item={true} sx={{marginTop:"10px"}}>
                 <NGInputLabel label={t("att.timeSheet.To")}/>
               </Grid>
              <Grid item={true} xs={3}>
              <Controller
                control={BreakTimeSettingForm.control}
                name={"break_end_1"}
                render={({field, fieldState}) =>
                    <TimePicker
                      value={field.value || null}
                      ampm={false}
                      onChange={(newValue: any) => {
                        if (newValue <= BreakTimeSettingForm.getValues("break_start_1")) {
                          SnackbarUtil.error(t("export.Break start time cannot greater than the end time"), {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
                          return;
                        }
                        field.onChange(newValue)
                      }}
                      disabled={schedule_used!=0}
                      renderInput={(fieldProps) => {
                        return <TextField
                          size={"medium"}
                          fullWidth={true}
                          {...fieldProps}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message || ""}
                       />
                      }}
                    />
                  }
              />
              </Grid>
              <Grid item={true} xs={3}>
                <Box sx={{ display: 'flex', alignItems: 'flex-end'}}>
                  <Controller
                    control={BreakTimeSettingForm.control}
                    name={"pairing_rule_1"}
                    render={({field}) => {
                        return (
                        <Select
                          {...field}
                          size={"medium"}
                          fullWidth={true}
                          disabled={schedule_used!=0}
                          defaultValue={"auto_deducted"}
                        >
                          {/* <MenuItem value={"punch_required"}>{t("att.timeSheet.Punch Required")}</MenuItem> */}
                          <MenuItem value={"auto_deducted"}>{t("att.timeSheet.Auto Deducted")}</MenuItem>
                        </Select>
                      )
                    }}
                  />
                </Box>
              </Grid>
              <Grid item={true} >
                <IconButton color="primary" aria-label="add to shopping cart" size="large" disabled={schedule_used!=0} sx={{marginTop: '-8px'}}>
                  <AddBoxIcon  fontSize="inherit"
                               onClick={() => {
                                if (breakTimeCount === 100){
                                  setBreakTimeCount(110)
                                }
                                else if (breakTimeCount === 110){
                                  setBreakTimeCount(111)
                                }
                                else if (breakTimeCount === 101){
                                  setBreakTimeCount(111)
                                }
                                else{
                                  enqueueSnackbar(t("att.timeSheet.The break time cannot exceed 3 data records"),
                                    {
                                    variant: "error",
                                    anchorOrigin: {
                                      vertical: "top",
                                      horizontal: "center"
                                    }
                                  });
                                }
                              }}
                  />
                </IconButton>
               </Grid>
            </Grid>
            {breakTimeCount === 110 || breakTimeCount === 111 ?
            <Grid container={true} rowSpacing={2} columnSpacing={3} sx={{marginTop: "10px"}}>
              <Grid item={true} sx={{marginTop:"10px"}}>
                 <NGInputLabel label={t("att.timeSheet.From")}/>
               </Grid>
              <Grid item={true} xs={3}>
                <Controller
                  control={BreakTimeSettingForm.control}
                  name={"break_start_2"}
                  render={({field, fieldState}) =>
                      <TimePicker
                        value={field.value || null}
                        ampm={false}
                        onChange={(newValue:any) => {
                          if (newValue >= BreakTimeSettingForm.getValues("break_end_2")) {
                            SnackbarUtil.error(t("export.Break start time cannot greater than the end time"), {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
                            return;
                          }
                          field.onChange(newValue)
                        }}
                        disabled={schedule_used!=0}
                        renderInput={(fieldProps) => {
                          return <TextField
                            size={"medium"}
                            fullWidth={true}
                            {...fieldProps}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message || ""}
                          />
                        }}
                      />
                  }
                />
              </Grid>
              <Grid item={true} sx={{marginTop:"10px"}}>
                 <NGInputLabel label={t("att.timeSheet.To")}/>
               </Grid>
              <Grid item={true} xs={3}>
              <Controller
                control={BreakTimeSettingForm.control}
                name={"break_end_2"}
                render={({field, fieldState}) =>
                    <TimePicker
                      value={field.value || null}
                      ampm={false}
                      onChange={(newValue: any) => {
                        if (newValue <= BreakTimeSettingForm.getValues("break_start_2")) {
                          SnackbarUtil.error(t("export.Break start time cannot greater than the end time"), {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
                          return;
                        }
                        field.onChange(newValue)
                      }}
                      disabled={schedule_used!=0}
                      renderInput={(fieldProps) => {
                        return <TextField
                          size={"medium"}
                          fullWidth={true}
                          {...fieldProps}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message || ""}
                       />
                      }}
                    />
                  }
              />
              </Grid>
              <Grid item={true} xs={3}>
                <Box sx={{ display: 'flex', alignItems: 'flex-end'}}>
                  <Controller
                    control={BreakTimeSettingForm.control}
                    name={"pairing_rule_2"}
                    render={({field}) => {
                        return (
                        <Select
                          {...field}
                          size={"medium"}
                          fullWidth={true}
                          disabled={schedule_used!=0}
                          defaultValue={"auto_deducted"}
                        >
                          {/* <MenuItem value={"punch_required"}>{t("att.timeSheet.Punch Required")}</MenuItem> */}
                          <MenuItem value={"auto_deducted"}>{t("att.timeSheet.Auto Deducted")}</MenuItem>
                        </Select>
                      )
                    }}
                  />
                </Box>
              </Grid>
              <Grid item={true} sx={{marginTop:"3px"}} >
                <IconButton color="default" aria-label="add to shopping cart" size="large" disabled={schedule_used!=0} sx={{marginTop: '-8px'}}>
                  <RemoveIcon  fontSize="inherit"
                              onClick={() => {
                                if (breakTimeCount === 110){
                                  setBreakTimeCount(100)
                                }
                                else{
                                  setBreakTimeCount(101)
                                }
                              }}
                  />
                </IconButton>
               </Grid>
            </Grid>  : <Grid></Grid>}
            {breakTimeCount === 111 || breakTimeCount === 101?
            <Grid container={true} rowSpacing={2} columnSpacing={3} component={"form"} sx={{marginTop: "10px"}}>
              <Grid item={true} sx={{marginTop:"10px"}}>
                 <NGInputLabel label={t("att.timeSheet.From")}/>
               </Grid>
              <Grid item={true} xs={3}>
                <Controller
                  control={BreakTimeSettingForm.control}
                  name={"break_start_3"}
                  render={({field, fieldState}) =>
                      <TimePicker
                        value={field.value || null}
                        ampm={false}
                        onChange={(newValue:any) => {
                          if (newValue >= BreakTimeSettingForm.getValues("break_end_3")) {
                            SnackbarUtil.error(t("export.Break start time cannot greater than the end time"), {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
                            return;
                          }
                          field.onChange(newValue)
                        }}
                        disabled={schedule_used!=0}
                        renderInput={(fieldProps) => {
                          return <TextField
                            size={"medium"}
                            fullWidth={true}
                            {...fieldProps}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message || ""}
                          />
                        }}
                      />
                  }
                />
              </Grid>
              <Grid item={true} sx={{marginTop:"10px"}}>
                 <NGInputLabel label={t("att.timeSheet.To")}/>
               </Grid>
              <Grid item={true} xs={3}>
              <Controller
                control={BreakTimeSettingForm.control}
                name={"break_end_3"}
                render={({field, fieldState}) =>
                    <TimePicker
                      value={field.value || null}
                      ampm={false}
                      onChange={(newValue: any) => {
                        if (newValue <= BreakTimeSettingForm.getValues("break_start_3")) {
                          SnackbarUtil.error(t("export.Break start time cannot greater than the end time"), {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
                          return;
                        }
                        field.onChange(newValue)
                      }}
                      disabled={schedule_used!=0}
                      renderInput={(fieldProps) => {
                        return <TextField
                          size={"medium"}
                          fullWidth={true}
                          {...fieldProps}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message || ""}
                       />
                      }}
                    />
                  }
              />
              </Grid>
              <Grid item={true} xs={3}>
                <Box sx={{ display: 'flex', alignItems: 'flex-end'}}>
                  <Controller
                    control={BreakTimeSettingForm.control}
                    name={"pairing_rule_3"}
                    render={({field}) => {
                        return (
                        <Select
                          {...field}
                          size={"medium"}
                          fullWidth={true}
                          disabled={schedule_used!=0}
                          defaultValue={"auto_deducted"}
                        >
                          {/* <MenuItem value={"punch_required"}>{t("att.timeSheet.Punch Required")}</MenuItem> */}
                          <MenuItem value={"auto_deducted"}>{t("att.timeSheet.Auto Deducted")}</MenuItem>
                        </Select>
                      )
                    }}
                  />
                </Box>
              </Grid>
              <Grid item={true} sx={{marginTop:"3px"}} >
                <IconButton color="default" aria-label="add to shopping cart" size="large"  disabled={schedule_used!=0} sx={{marginTop: '-8px'}}>
                  <RemoveIcon  fontSize="inherit"
                              onClick={() =>{
                                if (breakTimeCount === 111){
                                  setBreakTimeCount(110)
                                }
                                else{
                                  setBreakTimeCount(100)
                                }
                              }}
                  />
                </IconButton>
               </Grid>
            </Grid>  : <Grid></Grid>}
          </Grid>
        </Grid>
        </FormProvider>
    </Accordion>
  )
};
export default forwardRef(BreakTimeSetting)

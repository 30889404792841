import request from "../../../utils/Request";
import { ATTEndpoints } from "../endpoints";
import qs from "qs";

const list = async (params?: Record<string, any>) => {
  const response = await request.authorizedAxios().get(ATTEndpoints.ATT_PUNCH_RECORD_URI, {params, paramsSerializer: params => qs.stringify(params, {indices: false})});
  return response?.data;
}

const addPunchRecord = async (data: any) => {
  const response = await request.authorizedAxios().post(ATTEndpoints.ATT_PUNCH_RECORD_ADD_URI, data)
  return response?.data;
};

const update = async (id: string, data: any) => {
  const response = await request.authorizedAxios().put(`${ATTEndpoints.ATT_PUNCH_RECORD_ADD_URI}${id}/`, data);
  return response;
}

const retrieve = async (id: string) => {
  const response = await request.authorizedAxios().get(`${ATTEndpoints.ATT_PUNCH_RECORD_ADD_URI}${id}/`);
  return response;
}

const destroy = async (id: string) => {
  const response = await request.authorizedAxios().delete(`${ATTEndpoints.ATT_PUNCH_RECORD_ADD_URI}${id}/`);
  return response;
}


const PunchRecordService = {
  list,
  addPunchRecord,
  update,
  destroy,
  retrieve,
}

export default PunchRecordService;

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { DatePicker } from "@mui/x-date-pickers";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Grid, Stack, TextField, Paper, Typography } from "@mui/material";
import { object, TypeOf, z } from "zod";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import moment from "moment/moment";

import WebURL from '@/urls';
import SnackbarUtil from "@/utils/SnackbarUtil";
import { DeviceListInputDrawer } from "./drawers/DeviceListDrawer"
import { CancelButton, SavingButton } from "@/components/data-grid/CustomButton";
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import ScheduleService from "@/services/att/services/ScheduleService";
import EmpPicker from '@/components/EmpPicker';
import TimesheetPicker from "@/pages/att/Schedule/components/drawers/TimesheetPicker";
import BackGo from "@/components/back-go/BackGo";

const NewSchedule: React.FC = () => {
  const { t } = useTranslation();

  const scheduleValidate = object({
    employee_id:
      z.array(
        z.record(
          z.string(), z.string().nullable()
        ), { required_error: t("common.This field is required!") }
      ),
    timesheet:
      z.record(
        z.string(), z.any(), { required_error: t("common.This field is required!") }
      ),
    device_list:
      z.array(
        z.string()
      ).optional(),
    assigned_start:
      z.date(
        {
          errorMap: (issue, { defaultError }) =>
            ({ message: issue.code === "invalid_date" ? t("common.Invalid time format.") : issue.code === "invalid_type" ? t("common.This field is required!") : defaultError })
        }).or(z.string()),
    assigned_end:
      z.date(
        {
          errorMap: (issue, { defaultError }) =>
            ({ message: issue.code === "invalid_date" ? t("common.Invalid time format.") : issue.code === "invalid_type" ? t("common.This field is required!") : defaultError })
        }).or(z.string()),
    assigned_type: z.string(),
    overwrite: z.string(),
  })
  type scheduleInput = TypeOf<typeof scheduleValidate>
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  const scheduleForm = useForm<scheduleInput>({
    resolver: zodResolver(scheduleValidate),
    defaultValues: {
      assigned_start: today,
      assigned_end: new Date(today.getFullYear(), today.getMonth() + 1, 0),
      assigned_type: "employee",
      overwrite: 'yes',
    }
  })

  const navigate = useNavigate();
  const [devopen, setDevOpen] = React.useState(false);
  const [isLoading, setLoading] = useState<boolean>(false);

  const handleSelectDeviceOpen = () => {
    const employees = scheduleForm.getValues("employee_id")
    if (employees === undefined || employees.length === 0) {
      SnackbarUtil.error(t("att.schedule.Select person first"), { anchorOrigin: { vertical: 'top', horizontal: 'center' } })
      return;
    }
    setDevOpen(true);
  }

  const handleSelectDeviceClose = () => {
    setDevOpen(false);
  }

  const handleSelectDeviceSave = (value: any) => {
    console.log('>>>>>>>>SelectDevice:', value)
    if (value) {
      scheduleForm.setValue("device_list", value)
    }
  }

  const onSubmit = async () => {
    const data = scheduleForm.getValues()
    if (data.employee_id === undefined || data.employee_id.length === 0) {
      SnackbarUtil.error(t("att.schedule.Select person first"), { anchorOrigin: { vertical: 'top', horizontal: 'center' } })
      return;
    }
    if (!data.assigned_start) {
      SnackbarUtil.error(t("export.Start date cannot be null."), {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        }
      })
      return;
    }
    if (!data.assigned_end) {
      SnackbarUtil.error(t("export.End date cannot be null."), {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
      return;
    }
    data.assigned_start = moment(data.assigned_start).format('yyyy-MM-DD')
    data.assigned_end = moment(data.assigned_end).format('yyyy-MM-DD')
    data.employee_id = data.employee_id.map((emp: any) => emp?.id)
    data.timesheet = data.timesheet?.id
    data.device_list = data.device_list || []
    setLoading(true)
    ScheduleService.addSchedule(data).then((result) => {
      if (result.status < 400) {
        let msg = t("att.schedule.Successfully save schedule")
        if (data.device_list && data.device_list.length == 1) {
          msg += t("att.schedule.Success synchronize the data of person", { count: data.employee_id?.length })
        } else if (data.device_list && data.device_list.length > 1) {
          msg += t("att.schedule.Success synchronize the data of people", { count: data.employee_id?.length })
        }
        SnackbarUtil.success(msg)
        navigate(WebURL.ATT_SCHEDULE);
      } else {
        console.log("Failure===, ", result)
        SnackbarUtil.error(t("common.failure"), { anchorOrigin: { vertical: 'top', horizontal: 'center' } })
      }
    }).finally(() => {
      setLoading(false);
    });
  };
  return (
    <Grid style={{paddingRight: '24px', height: 'calc(100% - 86px)', boxSizing: 'border-box', position: 'relative', width: '100%'}}>
      <CustomBreadcrumbs record={[
        // { path: WebURL.ATT_SCHEDULE, label: t("att.schedule.Shift schedule") },
        { label: t("att.schedule.Add shift schedule") },
      ]} />
      <BackGo title={t("att.schedule.Add shift schedule")}></BackGo>
      <Grid sx={{height: 'calc(100% - 68px)', backgroundColor: '#ffffff', marginTop: '12px'}}>
        <Typography padding={"16px 0 10px 16px"} sx={{fontWeight: 'bold', fontSize: '16px'}}> {t("att.schedule.Basic Settings")} </Typography>
        <Paper style={{ minHeight: '200px', backgroundColor: '#fff', borderRadius: '8px', padding: '0 15px 15px 15px', boxShadow: 'none' }}>
          <Grid container={true}>
            <Grid container={true} component={"form"} columnSpacing={4} rowSpacing={2}>
              <Grid item xs={12} />
              <Grid item xs={4}>
                <Grid sx={{display: "flex"}}>
                  <Typography>
                    {t("common.Select person")}
                  </Typography>
                  <Typography color={"red"}>
                    *
                  </Typography>
                </Grid>
                <Controller
                  control={scheduleForm.control}
                  name="employee_id"
                  render={({ field, fieldState }) =>
                    <EmpPicker
                      multi={true}
                      error={!!fieldState.error}
                      value={field.value}
                      onChange={field.onChange}
                      helperText={fieldState.error?.message || ""}
                    />
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <Grid sx={{display: "flex"}}>
                  <Typography>
                    {t("common.Select timesheet")}
                  </Typography>
                  <Typography color={"red"}>
                    *
                  </Typography>
                </Grid>
                <Controller
                  control={scheduleForm.control}
                  name="timesheet"
                  render={({field, fieldState}) =>
                    <TimesheetPicker
                      error={!!fieldState.error}
                      value={field.value}
                      onChange={field.onChange}
                      helperText={fieldState.error?.message || ""}
                    />
                  }
                />
              </Grid>
              <Grid item xs={4} />
              <Grid item xs={4} >
                <Typography>
                  {t("common.Start Date")}
                </Typography>
                <Controller
                  control={scheduleForm.control}
                  name="assigned_start"
                  render={({ field, fieldState }) => (
                    <DatePicker
                      onChange={(date: any) => {
                        if (!date) {
                          SnackbarUtil.error(t("export.Start date cannot be null."), {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
                          field.onChange('')
                          return;
                        }
                        if (date > scheduleForm.getValues("assigned_end")) {
                          SnackbarUtil.error(t("export.Start date cannot greater than end date"), {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
                          return;
                        }
                        field.onChange(date)}}
                      // inputFormat={"dd-MM-yyyy"}
                      value={field.value || null}
                      renderInput={(params) =>
                        <TextField
                          {...params}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message || ""}
                          fullWidth
                        />
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography>
                  {t("common.End Date")}
                </Typography>
                <Controller
                  control={scheduleForm.control}
                  name="assigned_end"
                  render={({ field, fieldState }) => (
                    <DatePicker
                      onChange={(date: any) => {
                        if (!date) {
                          SnackbarUtil.error(t("export.End date cannot be null."), {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
                          field.onChange('')
                          return;
                        }
                        if (date < scheduleForm.getValues("assigned_start")) {
                          SnackbarUtil.error(t("export.End date cannot less than start date"), {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
                          return;
                        }
                        field.onChange(date)
                      }}
                      // inputFormat={"dd-MM-yyyy"}
                      value={field.value || null}
                      renderInput={(params) =>
                        <TextField
                          {...params}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message || ""} fullWidth
                        />
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4} />
              <Grid item xs={6} />
              <Grid item xs={6} />
            </Grid>
          </Grid>
        </Paper>
        <Paper style={{ backgroundColor: '#fff', borderTop: '1px solid #F2F5F8', boxShadow: 'none', borderRadius: '0' }}>
          <Grid>
            <Grid item xs={3} sx={{ padding: '10px', display: 'flex', alignItems: 'center' }}
                  container onClick={handleSelectDeviceOpen}>
              <Typography>
                {t("att.schedule.Person clock in Device")}
              </Typography>
              <IconButton>
                <KeyboardArrowRightIcon />
              </IconButton>
            </Grid>
          </Grid>
          <DeviceListInputDrawer
            open={devopen}
            onClose={handleSelectDeviceClose}
            onSave={handleSelectDeviceSave}
          />
        </Paper>

        <Grid item xs={12} sx={{borderTop: '1px solid #F2F5F8', position: 'absolute', bottom: '16px', paddingLeft: '16px', paddingTop: '16px', width: '100%', boxSizing: 'border-box'}}>
          <Stack spacing={3} direction={"row"} justifyContent="start">
            <CancelButton />
            <SavingButton loading={isLoading} onClick={scheduleForm.handleSubmit(onSubmit)} />
          </Stack>
        </Grid>
      </Grid>

    </Grid>
  )
}
export default NewSchedule

import React, {useEffect, useState, useImperativeHandle, forwardRef} from "react";
import {Box, Paper, Grid, TextField, Button, IconButton, Stack, Drawer, Typography} from "@mui/material";
import {object, TypeOf, z} from "zod";
import {Controller, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {useSnackbar} from "notistack";
import {CancelButton, SavingButton} from "@/components/data-grid/CustomButton";
import {useTranslation} from "react-i18next";
import NGInputLabel from "@/components/ngteco-form/NGInputLabel";
import EmpPicker from "@/components/EmpPicker";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {TimePicker} from "@mui/x-date-pickers/TimePicker";
import Util from "@/utils/Util";
import PunchRecordService from "@/services/att/services/PunchRecordService";
import {parse} from "date-fns";

const PunchView = (props: any, ref: any) => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const [saving, setSaving] = React.useState<boolean>(false)
  const [oldData, setOldData] = React.useState<any>()
  const [state, setState] = useState({id: ""}) // 编辑部门id值
  const [title, setTitle] = useState("")
  const PunchAddValidate = object({
    employee_code: z.string().nullable().optional(),
    punch_date:
      z.date(
        {
          errorMap: (issue, { defaultError }) =>
            ({ message: issue.code === "invalid_date" ? t("common.Invalid date format.") : issue.code === "invalid_type" ? t("common.This field is required!") : defaultError })
        }),
    punch_time:
      z.date(
        {
          errorMap: (issue, { defaultError }) =>
            ({ message: issue.code === "invalid_date" ? t("common.Invalid time format.") : issue.code === "invalid_type" ? t("common.This field is required!") : defaultError })
        })
  })

  type PunchAddForm = TypeOf<typeof PunchAddValidate>;
  const punchAddForm = useForm<PunchAddForm>({
    resolver: zodResolver(PunchAddValidate),
    defaultValues: {
      // punchDate: new Date(),
      // punchTime: new Date(),
    }
  })
  const [open, setOpen] = useState(false)

  const onClose = () => {
    punchAddForm.reset()
    setOpen(false)
  }
  useImperativeHandle(ref, () => ({
    showDetail: async (id: string) => {
      setState({id})
      setTitle(t("common.Edit Punch Record"))
      setOpen(true)
    }
  }));

  useEffect(() => {
    if (state?.id) {
      PunchRecordService.retrieve(state.id || "").then((res) => {
        punchAddForm.setValue("employee_code", res.data.data?.employee_code);
        if (typeof res.data.data?.punch_date === 'string') {
          punchAddForm.setValue("punch_date", parse(res.data.data?.punch_time.toString().slice(0, 10), "yyyy-MM-dd", new Date()));
        } else {
          punchAddForm.setValue("punch_date", res.data.data?.punch_date);
        }
        if (typeof res.data.data?.punch_time === 'string') {
          punchAddForm.setValue("punch_time", parse(res.data.data?.punch_time.toString().slice(11, 16), "HH:mm", new Date()));
        } else {
          punchAddForm.setValue("punch_time", res.data.data?.punch_time);
        }
        setOldData({
          employee_name: res.data.data?.employee_name,
          punch_time: res.data.data?.punch_time,
          punch_date: res.data.data?.punch_date
        })
      });
    }
  }, [state]);

  const onSubmit = async () => {
    setSaving(true)
    try {
      let result;
      if (state?.id) {
        const value = punchAddForm.getValues()
        const data = {
          ...value,
          oldData: oldData,
        }
        result = await PunchRecordService.update(state.id, data);
        if (result.status < 400) {
          enqueueSnackbar(t("common.Punch record updated successfully."), {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          });
          if (props.reloadTable) {
            props.reloadTable()
          }
        }
      }
    } catch (e) {
      console.log("error===", e)
    } finally {
      setSaving(false)
      onClose()
    }
  }

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={onClose}
    >
      <Grid sx={{
        minWidth: '300px',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        position: 'relative',
      }}>
        <Grid sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '56px',
          padding: '0px 24px',
          borderBottom: '1px solid #F2F5F8',
        }}>
          <Grid>{title}</Grid>
          <Grid>
            <i style={{cursor: "pointer"}} className="icon iconfont icon-ic_close" onClick={onClose}></i>
          </Grid>
        </Grid>
        <Paper sx={{padding: '20px', width: '400px', boxShadow: 'none'}}>
          <Grid
            container
          >
            <Grid item={true} xs={12}>
              <Box sx={{marginBottom: '8px'}}>
                <NGInputLabel label={t("common.Person ID")}/>
              </Box>
              <Controller
                name="employee_code"
                control={punchAddForm.control}
                render={({field, fieldState}) =>
                  <TextField
                    size={"medium"}
                    disabled={true}
                    fullWidth={true}
                    value={field.value}
                  />
                }
              />
            </Grid>
          </Grid>
          <Grid
            container
          >
            <Grid item={true} xs={12} sx={{marginTop: "20px"}}>
              <Box>
                <NGInputLabel label={t("common.Set mend att-punch date")}/>
              </Box>
              <Grid sx={{paddingTop: '10px'}}>
                <Controller
                  name="punch_date"
                  control={punchAddForm.control}
                  render={({field, fieldState}) =>
                    <DatePicker
                      disableFuture
                      value={field.value || null}
                      onChange={(newValue) => field.onChange(newValue)}
                      // inputFormat="yyyy-MM-dd"
                      renderInput={(params) => {
                        return (
                          <TextField
                            sx={{width: '100%'}}
                            {...params}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message || ""}
                          />
                        )
                      }
                      }
                    />
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sx={{marginTop: "20px"}}>
              <Grid container={true} rowSpacing={2} columnSpacing={6}>
                <Grid item={true} xs={12}>
                  <Box>
                    <Box>
                      <NGInputLabel label={t("common.Set mend att-punch time")}/>
                    </Box>
                    <Grid sx={{paddingTop: '10px'}}>
                      <Controller
                        control={punchAddForm.control}
                        name="punch_time"
                        render={({field, fieldState}) =>
                          <TimePicker
                            value={field.value || null}
                            onChange={(newValue) => field.onChange(newValue)}
                            ampm={false}
                            renderInput={(params) =>
                              <TextField
                                sx={{width: '100%'}}
                                {...params}
                                error={!!fieldState.error}
                                helperText={fieldState.error?.message || ""}
                              />
                            }
                          />
                        }
                      />
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid sx={{marginTop: "20px"}}>
            <Typography variant="h6">{t("common.Manual Log Edit Note.")}</Typography>
          </Grid>
        </Paper>
        <Grid sx={{borderTop: '1px solid #F2F5F8', position: 'absolute', bottom: '12px', left: '0', width: '100%'}}>
          <Grid style={{paddingTop: '12px', paddingLeft: '24px'}}>
            <Stack spacing={3} direction={"row"} justifyContent="start">
              <CancelButton onClick={onClose}/>
              <SavingButton loading={saving} onClick={punchAddForm.handleSubmit(onSubmit)}/>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Drawer>
  )
}

export default forwardRef(PunchView);

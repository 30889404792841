import { Box, Grid, MenuItem, Select} from "@mui/material";
import {ReactComponent as DefaultOrganization} from '@/assets/images/DefaultOrganization.svg';
import {useForm} from 'react-hook-form';
import { useEffect, useState, useRef} from "react";
import CompanyService from "@/services/auth/CompanyService";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import React from "react";
import {useTranslation} from "react-i18next";
import NGInputLabel from "@/components/ngteco-form/NGInputLabel";
import OrganDelete from "@/pages/company/OrganDelete";
import {ReactComponent as IconDelete} from '@/assets/icons/IconDeletes.svg';
import {ReactComponent as IconEdit} from '@/assets/icons/IconEdit.svg';
import ControlledTextFiled from "@/components/ngteco-hook-form/ControlledTextFiled";
import {zodResolver} from "@hookform/resolvers/zod";
import {object, TypeOf, z} from "zod";
import CustomRowButton from "@/components/data-grid/CustomRowButton";
import OrganEdit from "./OrganEdit";
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";

const OrganView: React.FC = () => {
  const {t} = useTranslation();
  const organEditRef = useRef<any>({})
  const creationSchema = object({
    name: z.string().min(1),
    code: z.string().min(1),
  })
  type creationInput = TypeOf<typeof creationSchema>;
  const organForm = useForm<creationInput>({
    resolver: zodResolver(creationSchema)
  });
  const {
    setValue,
    formState: {errors},
  } = organForm;
  const [country, setCountry] = useState<string>("");
  const [destroy, setDestroy] = useState<boolean>(false);
  const [companyLogo, setCompanyLogo] = useState<string>("");
  const name_text = t("organ.name text tip")
  const code_text = t("organ.code text tip")

  useEffect(() => {
    queryData()
  }, [])

  const queryData = () => {
    CompanyService.companyInfo().then(res => {
      setCompanyLogo(res.data.imagePreSignedURL)
      setValue("name", res.data.name);
      setValue("code", res.data.code);
      setCountry(res.data?.country || "");
    });
  }

  const ImageView = (props: any) => {
    return (
      <Card sx={{maxWidth: 345}}>
        <CardMedia
          component="img"
          height={props.height}
          image={companyLogo}
          alt="Company Logo"
        />
      </Card>
    )
  };

  const reloadData = () => {
    queryData()
  }

  return (
    <Grid
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: "24px",
        width: "calc(100% - 24px)",
        backgroundColor: "#ffffff",
        minHeight: "calc(100vh - 94px)",
        marginBottom: '24px'
      }}>
      <CustomBreadcrumbs record={[{label: t("common.Organization Profile")}]}/>
      <img src={'/images/img_banner.png'} alt={""} style={{ width: '100%', height: '240px' ,overflow: "initial", overflowClipMargin: 'initial'}}/>
      <Box style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", width: '100%'}}>
        <CustomRowButton insideSvgIcon={IconEdit} actionName={t("common.edit")} buttonProps={{
          key: "edit",
          onClick: () => {
            organEditRef?.current?.showDetail()
          }
        }}/>
        <CustomRowButton insideSvgIcon={IconDelete} actionName={t("common.delete")} buttonProps={{
          key: "delete",
          onClick: async () => {
            setDestroy(true)
          }
        }}/>
      </Box>
      <Grid container style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        width: "100%",
        marginTop: "-30px"
      }}>
        <Grid lg={6} item>
          <Grid sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid #AEAFB0',
            width: '100%',
            height: '240px',
            lineHeight: '50px',
            minWidth: '200px',
            marginBottom: '12px'
          }}>
            <Box sx={{height: '200px'}}>
              {
                companyLogo ? <ImageView height="200"/> : <DefaultOrganization/>
              }
            </Box>
          </Grid>
          <ControlledTextFiled
            hookForm={organForm}
            name={'name'}
            tooltip={name_text}
            label={t("organ.Organization Name")}
            fullWidth
            disabled={true}
          />
          <ControlledTextFiled
            hookForm={organForm}
            name={'code'}
            label={t("organ.Organization Code")}
            tooltip={code_text}
            disabled={true}
            fullWidth
          />
          <Grid>
            <NGInputLabel label={t("signUp.Choose your country")}/>
            <Select
              sx={{width: '100%'}}
              value={country}
              disabled
            >
              <MenuItem value={"USA"}>{t("common.America")}</MenuItem>
              <MenuItem value={"JPN"}>{t("common.Japan")}</MenuItem>
              <MenuItem value={"GER"}>{t("common.Germany")}</MenuItem>
              <MenuItem value={"FRA"}>{t("common.France")}</MenuItem>
              <MenuItem value={"SPA"}>{t("common.Spain")}</MenuItem>
              <MenuItem value={"UK"}>{t("common.United Kingdom")}</MenuItem>
              <MenuItem value={"ITA"}>{t("common.Italy")}</MenuItem>
              <MenuItem value={"CAN"}>{t("common.Canada")}</MenuItem>
              <MenuItem value={"MEX"}>{t("common.Mexico")}</MenuItem>
              <MenuItem value={"MAL"}>{t("common.Malaysia")}</MenuItem>
              <MenuItem value={"THA"}>{t("common.Thailand")}</MenuItem>
              <MenuItem value={"PHI"}>{t("common.Philippines")}</MenuItem>
            </Select>
          </Grid>
        </Grid>
      </Grid>
      <OrganDelete open={destroy} setOpen={setDestroy} ImageView={companyLogo ? <ImageView height="70"/> : <DefaultOrganization height="70" width="70"/>}/>
      <OrganEdit ref={organEditRef} reloadData={reloadData}></OrganEdit>
    </Grid>
  )
}

export default OrganView;

import React, {useEffect, useState} from "react";
import {Box, Grid, TextField, Paper, Typography, Stack} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers";
import {useLocation, useNavigate} from "react-router-dom";
import ScheduleService from "@/services/att/services/ScheduleService";
import TimesheetService from "@/services/att/services/TimesheetService";
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import { z, object, TypeOf } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import WebURL from "@/urls";
import BackGo from "@/components/back-go/BackGo";
import SnackbarUtil from "@/utils/SnackbarUtil";
import TimesheetPicker from "@/pages/att/Schedule/components/drawers/TimesheetPicker";
import {CancelButton, SavingButton} from "@/components/data-grid/CustomButton";
import moment from "moment";
import Util from "@/utils/Util";

const scheduleSchema = object({
  // assigned_start: z.date(),
  // assigned_end: z.date(),
  employee_name: z.string(),
  timesheet_name:
    z.record(
      z.string(), z.any(), {required_error: t("common.This field is required!")}
    ),
  device_list:
    z.array(
      z.string()
    ).optional(),
  assigned_start:
    z.date(
      {
        errorMap: (issue, {defaultError}) =>
          ({message: issue.code === "invalid_date" ? t("common.Invalid time format.") : issue.code === "invalid_type" ? t("common.This field is required!") : defaultError})
      }).or(z.string()),
  assigned_end:
    z.date(
      {
        errorMap: (issue, {defaultError}) =>
          ({message: issue.code === "invalid_date" ? t("common.Invalid time format.") : issue.code === "invalid_type" ? t("common.This field is required!") : defaultError})
      }).or(z.string()),
  assigned_type: z.string(),
  overwrite: z.string(),
  employee_id: z.string().optional(),
})
type scheduleType = TypeOf<typeof scheduleSchema>;


const EditSchedule: React.FC = () => {
  const location = useLocation()
  const state = location.state as { id: string }
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [employeeID, setEmployeeID] = useState<string>('');
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  const scheduleForm = useForm<scheduleType>({
    resolver: zodResolver(scheduleSchema),
    defaultValues: {
      assigned_start: today,
      assigned_end: new Date(today.getFullYear(), today.getMonth() + 1, 0),
      assigned_type: "employee",
      overwrite: 'yes',
    }
  })

  useEffect(() => {
    ScheduleService.retrieve(state.id || "").then((res) => {
      // setStartDate(res.data.assigned_start);
      // setEndDate(res.data.assigned_end);
      scheduleForm.setValue('assigned_end', res.data.assigned_end);
      scheduleForm.setValue('assigned_start', res.data.assigned_start);
      scheduleForm.setValue('employee_name', res.data.employee_name);
      setEmployeeID(res.data.employee)
      // scheduleForm.setValue('timesheet_name', res.data.timesheet);
      TimesheetService.retrieve(res.data.timesheet || "").then((t_res) => {
        scheduleForm.setValue('timesheet_name', t_res.data);
      })
    })

  }, [])
  const onSubmit = async () => {
    const data = scheduleForm.getValues()
    if (!data.assigned_start) {
      SnackbarUtil.error(t("export.Start date cannot be null."), {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        }
      })
      return;
    }
    if (!data.assigned_end) {
      SnackbarUtil.error(t("export.End date cannot be null."), {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
      return;
    }
    data.assigned_start = moment(data.assigned_start).format('yyyy-MM-DD')
    data.assigned_end = moment(data.assigned_end).format('yyyy-MM-DD')
    data.timesheet_name = data.timesheet_name?.id
    data.employee_id = employeeID
    // data.device_list = data.device_list || []
    setLoading(true)
    ScheduleService.updateSchedule(state.id, data).then((result) => {
      if (result.status < 400) {
        navigate(WebURL.ATT_SCHEDULE);
      } else {
        console.log("Failure===, ", result)
        SnackbarUtil.error(t("common.failure"), { anchorOrigin: { vertical: 'top', horizontal: 'center' } })
      }
    }).finally(() => {
      setLoading(false);
    });
  };

  return (
    <Grid style={{paddingRight: '24px', height: 'calc(100% - 86px)', boxSizing: 'border-box', position: 'relative', width: '100%'}}>
      <CustomBreadcrumbs record={[
        // { path: WebURL.ATT_SCHEDULE, label: t("att.schedule.Shift schedule") },
        { label: t("att.schedule.Edit shift schedule") },
      ]} />
      <BackGo title={t("att.schedule.Edit shift schedule")}></BackGo>
      <Grid sx={{height: 'calc(100% - 68px)', backgroundColor: '#ffffff', marginTop: '12px'}}>
        <Typography padding={"16px 0 10px 16px"} sx={{fontWeight: 'bold', fontSize: '16px'}}> {t("att.schedule.Basic Settings")} </Typography>
        <Paper style={{ minHeight: '200px', backgroundColor: '#fff', borderRadius: '8px', padding: '0 15px 15px 15px', boxShadow: 'none' }}>
          <Grid container={true}>
            <Grid container={true} component={"form"} columnSpacing={4} rowSpacing={2}>
              <Grid item xs={12}/>
              <Grid item xs={4}>
                <Typography>
                  {t("common.Select person")}
                </Typography>
                <TextField
                  hiddenLabel
                  fullWidth
                  sx={{ padding: '10px 10px 10px 0' }}
                  InputProps={{
                    readOnly: true,
                  }}
                  disabled
                  {...scheduleForm.register("employee_name")}
                />
              </Grid>
              <Grid item xs={4}>
                <Grid sx={{display: "flex", marginBottom: "10px"}}>
                  <Typography>
                    {t("common.Select timesheet")}
                  </Typography>
                  <Typography color={"red"}>
                    *
                  </Typography>
                </Grid>
                <Controller
                  control={scheduleForm.control}
                  name="timesheet_name"
                  render={({field, fieldState}) =>
                    <TimesheetPicker
                      error={!!fieldState.error}
                      value={field.value}
                      onChange={field.onChange}
                      helperText={fieldState.error?.message || ""}
                    />
                  }
                />
              </Grid>
              <Grid item xs={4}/>
              <Grid item xs={4} >
                <Typography>
                  {t("common.Start Date")}
                </Typography>
                <Controller
                  control={scheduleForm.control}
                  name="assigned_start"
                  render={({ field, fieldState }) => (
                    <DatePicker
                      onChange={(date: any) => {
                        if (!date) {
                          SnackbarUtil.error(t("export.Start date cannot be null."), {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
                          field.onChange('')
                          return;
                        }
                        const assigned_end = scheduleForm.getValues("assigned_end")
                        if (typeof assigned_end === 'object') {
                          if (date > assigned_end) {
                            SnackbarUtil.error(t("export.Start date cannot greater than end date"), {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
                            return;
                          }
                        } else {
                          if (Util.datetime2string(date).slice(0, 10) > assigned_end) {
                            SnackbarUtil.error(t("export.Start date cannot greater than end date"), {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
                            return;
                          }
                        }
                        field.onChange(date)}}
                      // inputFormat={"dd-MM-yyyy"}
                      value={field.value || null}
                      renderInput={(params) =>
                        <TextField
                          {...params}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message || ""}
                          fullWidth
                        />
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography>
                  {t("common.End Date")}
                </Typography>
                <Controller
                  control={scheduleForm.control}
                  name="assigned_end"
                  render={({ field, fieldState }) => (
                    <DatePicker
                      onChange={(date: any) => {
                        if (!date) {
                          SnackbarUtil.error(t("export.End date cannot be null."), {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
                          field.onChange('')
                          return;
                        }
                        if (typeof scheduleForm.getValues("assigned_start") === 'object') {
                          if (date < scheduleForm.getValues("assigned_start")) {
                            SnackbarUtil.error(t("export.End date cannot less than start date"), {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
                            return;
                          }
                        } else {
                          if (Util.datetime2string(date).slice(0, 10) < scheduleForm.getValues("assigned_start")) {
                            SnackbarUtil.error(t("export.End date cannot less than start date"), {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
                            return;
                          }
                        }
                        field.onChange(date)
                      }}
                      // inputFormat={"dd-MM-yyyy"}
                      value={field.value || null}
                      renderInput={(params) =>
                        <TextField
                          {...params}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message || ""} fullWidth
                        />
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}/>
              <Grid item xs={6} />
              <Grid item xs={6} />
            </Grid>
          </Grid>
          <Grid sx={{marginTop: "20px"}}>
            <Typography variant="h6">{t("common.Schedule Edit Note.")}</Typography>
          </Grid>
        </Paper>
        <Grid item xs={12} sx={{borderTop: '1px solid #F2F5F8', position: 'absolute', bottom: '16px', paddingLeft: '16px', paddingTop: '16px', width: '100%', boxSizing: 'border-box'}}>
          <Stack spacing={3} direction={"row"} justifyContent="start">
            <CancelButton />
            <SavingButton loading={isLoading} onClick={scheduleForm.handleSubmit(onSubmit)} />
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  )
}
export default EditSchedule

import React, {Fragment, useEffect, useRef, useState} from "react";
import {Box, Breadcrumbs, Dialog, DialogTitle, Grid, IconButton, Link, Stack, Tooltip, Typography} from "@mui/material";
import {object, TypeOf, z} from "zod";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {useSnackbar} from "notistack";
import {CancelButton, SaveButton} from "@/components/data-grid/CustomButton";
import {useTranslation} from "react-i18next";
import {RemoveRedEyeOutlined} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import GenericService from "@/services/GenericService";
import {ATTEndpoints} from "@/services/att/endpoints";
import TimesheetService from "@/services/att/services/TimesheetService";
import KnowAboutThisPolicy from "@/pages/att/Timesheet/component/KnowAboutThis";
import NGTextField from "@/components/ngteco-form/NGTextField";
import NGInputLabel from "@/components/ngteco-form/NGInputLabel";
import TimesheetSetting from "@/pages/att/Timesheet/component/TimsheetSetting";
import PatternSetting, {PatternSettingFields} from "@/pages/att/Timesheet/component/PatternSetting";
import Switch from "@mui/material/Switch";
import BreakTimeSetting, {BreakTimeSettingFields} from "@/pages/att/Timesheet/component/BreakTimeSetting";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Util from "@/utils/Util";


const dialogStyle = {
  '& .MuiPaper-root': {width: '60vw', top: '1vh', overflowX: "hidden"},
  '.MuiDialog-paper': {maxWidth: '90vw'},
};

export function EditAction({setInfo}: any) {
  const {t} = useTranslation()

  return (
    <Tooltip title={t("common.View TimeSheet")}>
      <IconButton sx={{ "&:hover": { backgroundColor: "transparent" }}} onClick={() => {
        setInfo(true)
      }}>
        <i className="icon iconfont icon-ic_view" style={{ cursor: 'pointer', color: 'rgb(117, 130, 138)' }}></i>
        <span style={{
          fontSize: '14px',
          color: 'rgb(117, 130, 138)',
          cursor: "pointer",
          marginLeft: '4px',
          marginTop: '-2px'
        }}>{t("common.View TimeSheet")}</span>
      </IconButton>
    </Tooltip>
  )
}

export interface TimesheetEditProps {
  timesheetId: string,
}

const TimesheetEdit: React.FC<TimesheetEditProps> = (props) => {
  const [info, setInfo] = useState<boolean>(false)

  return (
    <Fragment>
      <Grid item={true}>
        <EditAction setInfo={setInfo}/>
      </Grid>
      <EditTimesheetDialog info={info} setInfo={setInfo} timesheetId={props.timesheetId}/>
    </Fragment>
  )
}

const EditTimesheetDialog = ({info, setInfo, timesheetId}: any) => {
  const {t} = useTranslation();
  const timesheetSchema = object({
    name: z.string().min(1, t("common.This field is required!"))
      .max(30, t("common.The length of field cannot exceed digits", {number: 30})),
    description: z.string().max(200, t("common.The length of field cannot exceed digits", {number: 200})),
  })
  type TimesheetInput = TypeOf<typeof timesheetSchema>;
  const {enqueueSnackbar} = useSnackbar();
  const patternRef = useRef<{ pattern: any }>();
  const breakTimeRef = useRef<{ breakTime: any }>();
  const sheetRef = useRef<{ sheet: any }>();
  const [timesheet] = useState<any[]>([]);
  const [periodCycle] = useState<number>(1);
  const [policy, setPolicy] = useState<string>("main");
  const [privacyPolicy, setPrivacyPolicy] = useState<boolean>(false);
  const [break_loading, setBreakLoading] = React.useState(true);
  const [overtime_loading, setOvertimeLoading] = React.useState(false);
  const [pattern] = useState<PatternSettingFields>({
    day_change: "00:00",
    punch_interval: 1,
    max_hrs: 10,
    pairing: 'first_last',
    overtime_remind: 0,
    required_record: "any",
  });
  const [breakTime] = useState<BreakTimeSettingFields>({
    break_times_flag: 100, break_switch: false,
    break_start_1: new Date('1990-01-01T12:00:00'), break_end_1: new Date('1990-01-01T12:30:00'),
    pairing_rule_1: "auto_deducted",
    break_start_2: new Date('1990-01-01T13:00:00'), break_end_2: new Date('1990-01-01T13:30:00'),
    pairing_rule_2: "auto_deducted",
    break_start_3: new Date('1990-01-01T14:00:00'), break_end_3: new Date('1990-01-01T14:30:00'),
    pairing_rule_3: "auto_deducted",
  });
  const [option, setOption] = React.useState('clocking');
  const {
    register,
    trigger,
    setValue,
    handleSubmit,
    formState: {errors},
    getValues
  } = useForm<TimesheetInput>({
    resolver: zodResolver(timesheetSchema),
  });
  useEffect(() => {
    if (timesheetId) {
      GenericService.retrieve(ATTEndpoints.ATT_TIMESHEET_URI, `${timesheetId}`).then((res) => {
        setValue("name", res.data?.name);
        setValue("description", res.data?.description);
        setOvertimeLoading(res.data?.pattern.overtime_flag);
        setOption(res.data?.pattern.option)
        if (res.data?.pattern.break_pairs.length === 0) {
          setBreakLoading(true);
        }
      })
    }
  }, []);
  const scrollTo = (name: string) => {
    const anchorElement = document.getElementsByName(name)[0]
    if (anchorElement) {
      anchorElement.scrollIntoView({behavior: "smooth"})
    }
  }
  const onSubmit = async (formData: any) => {
    let result;
    const sheetData = await sheetRef.current?.sheet();
    const patternData = await patternRef.current?.pattern();
    const punch_interval = patternData?.punch_interval
    let max_punch_interval = 999;
    sheetData?.sheet.map((sheet_obj: any) => {
      const minuteDiff = Util.timeFn(Util.time2datetime(sheet_obj.check_in_start),
        Util.time2datetime(sheet_obj.check_out_end))
      if (minuteDiff < max_punch_interval) {
        max_punch_interval = minuteDiff
      }
    })
    if (punch_interval > max_punch_interval) {
      enqueueSnackbar(t("att.timeSheet.The Punch Interval cannot exceed the check-out and check-in interval"), {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center"
        }
      });
      return
    }
    const breakTimeData = await breakTimeRef.current?.breakTime();
    // patternData.check_in = patternData.check_in.toString().slice(16, 21)
    // patternData.check_out = patternData.check_out.toString().slice(16, 21)
    // patternData.day_change = patternData.day_change.toString().slice(16, 21)
    const break_pairs = []
    if (break_loading) {
      breakTimeData.break_start_1 = breakTimeData.break_start_1.toString().slice(16, 21)
      breakTimeData.break_end_1 = breakTimeData.break_end_1.toString().slice(16, 21)
      breakTimeData.break_start_2 = breakTimeData.break_start_2.toString().slice(16, 21)
      breakTimeData.break_end_2 = breakTimeData.break_end_2.toString().slice(16, 21)
      breakTimeData.break_start_3 = breakTimeData.break_start_3.toString().slice(16, 21)
      breakTimeData.break_end_3 = breakTimeData.break_end_3.toString().slice(16, 21)
      breakTimeData.break_switch = break_loading
      break_pairs.push({
        "start": breakTimeData.break_start_1,
        "end": breakTimeData.break_end_1,
        "pairing_rule": breakTimeData.pairing_rule_1
      })
      if (breakTimeData.break_times_flag.toString().charAt(1) === "1") {
        break_pairs.push({
          "start": breakTimeData.break_start_2,
          "end": breakTimeData.break_end_2,
          "pairing_rule": breakTimeData.pairing_rule_2
        })
      }
      if (breakTimeData.break_times_flag.toString().charAt(2) === "1") {
        break_pairs.push({
          "start": breakTimeData.break_start_3,
          "end": breakTimeData.break_end_3,
          "pairing_rule": breakTimeData.pairing_rule_3
        })
      }
    }
    patternData["break_pairs"] = break_pairs
    const mix_patternData = {...sheetData, ...patternData}
    delete mix_patternData.sheet
    if (sheetData && patternData) {
      const data = {
        ...formData,
        ...sheetData,
        pattern: mix_patternData
      };
      if (timesheetId) {
        result = await TimesheetService.updateTimesheet(timesheetId, data);
      } else if (sheetData.cycle < 1 || sheetData.cycle > 12 || isNaN(sheetData.cycle) ||
        String(sheetData.cycle).indexOf(".") > -1) {
        scrollTo("cycle")
      } else {
        result = await TimesheetService.addTimesheet(data);
        if (result.status < 400) {
          enqueueSnackbar("Time sheet created successfully.", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          });
        }
      }
    }
  };

  return (
    <Dialog
      open={info}
      // onClose={()=>setInfo(false)}
      sx={dialogStyle}
    >
      <DialogTitle>
        {t("common.View TimeSheet")}
        <IconButton
          aria-label="close"
          onClick={() => setInfo(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon/>
        </IconButton>
        {policy === "know" ? <KnowAboutThisPolicy setPolicy={setPolicy} setPrivacyPolicy={setPrivacyPolicy}/> :
          <Grid container={true} rowSpacing={1} sx={{margin: "0"}}>
            <Grid item xs={12} sx={{marginLeft: "10px"}}>
              <Grid container={true} rowSpacing={2} columnSpacing={6} component={"form"}
                    onSubmit={handleSubmit(onSubmit)}>
                <Grid item={true} xs={6}>
                  <NGTextField
                    fullWidth={true}
                    placeholder={t("common.Timesheet Name")}
                    margin={"normal"}
                    label={t("common.Timesheet Name")}
                    required
                    InputLabelProps={{
                      shrink: true
                    }}
                    error={!!errors['name']}
                    disabled={!!timesheetId}
                    helperText={errors['name']?.message || ""}
                    {...register("name")}
                  />
                </Grid>
                <Grid item={true} xs={6}>
                  <NGTextField
                    fullWidth={true}
                    placeholder={t("common.Timesheet Description")}
                    label={t("common.Timesheet Description")}
                    InputLabelProps={{
                      shrink: true
                    }}
                    disabled={!!timesheetId}
                    margin={"normal"}
                    error={!!errors['description']}
                    helperText={errors['description']?.message || ""}
                    {...register("description")}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid sx={{marginLeft: "10px"}} item xs={12}>
              <NGInputLabel label={t("common.Schedule Rule")}/>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{
                borderRadius: '5px',
                background: '#fff',
                marginLeft: "10px",
                marginTop: "0px",
                // paddingBottom: "10px"
              }}>
                <Grid sx={{marginLeft: "-20px", marginTop: "-20px"}} container={true} component={"form"}>
                  <TimesheetSetting ref={sheetRef} cycle={periodCycle} period={"week"} timesheet={timesheet}
                                    view_data={timesheetId} setOption={setOption}/>
                </Grid>

              </Box>
            </Grid>
            <Grid sx={{marginLeft: "10px"}} item xs={12}>
              <NGInputLabel label={t("common.ATT records statistics rule")}/>
            </Grid>

            <Grid item xs={12}>
              <Box sx={{
                borderRadius: '5px',
                background: '#fff',
                marginLeft: "10px",
                marginTop: "0px",
              }}>
                <Grid sx={{padding: "1px"}} container={true} component={"form"}>

                  <PatternSetting ref={patternRef} pattern={pattern} view_data={timesheetId} option={option}/>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{
                borderRadius: '5px',
                background: '#fff',
                marginLeft: "10px",
                marginTop: "0px",
              }}>
                <Grid sx={{padding: "1px"}} container={true} component={"form"}>
                  <Grid container={true} component={"form"} item xs={10}>
                    <Grid container={true} xs={5}>
                      <NGInputLabel label={t("att.timeSheet.Break Time")}/>
                      <Switch
                        sx={{marginTop: "-6px"}}
                        checked={break_loading}
                        disabled={!!timesheetId}
                        onChange={() => setBreakLoading(!break_loading)}
                        name="Break Time"
                        color="primary"
                      />
                    </Grid>
                    <Grid container={true} xs={5}>
                      <NGInputLabel label={t("Overtime")}/>
                      <Switch
                        sx={{marginTop: "-6px"}}
                        checked={overtime_loading}
                        disabled={!!timesheetId}
                        onChange={() => setOvertimeLoading(!overtime_loading)}
                        name="overtime"
                        color="primary"
                      />
                    </Grid>
                  </Grid>
                  <Grid columnSpacing={12}>
                    {break_loading ? <BreakTimeSetting ref={breakTimeRef} break_time={breakTime} view_data={timesheetId}/> :
                      <Grid></Grid>}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            {/*<Grid item xs={12} sx={{p: "0 16px", display: 'flex', justifyContent: 'end'}}>*/}
            {/*  <Box sx={{display: "inline-flex"}}>*/}
            {/*    <Link*/}
            {/*      underline={"none"}*/}
            {/*      onClick={() => setPolicy("know")}*/}
            {/*      sx={{*/}
            {/*        cursor: "pointer",*/}
            {/*        padding: "0 8px",*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      <Typography>{t("organ.know more about this?")}</Typography>*/}
            {/*    </Link>*/}
            {/*  </Box>*/}
            {/*</Grid>*/}
            <Grid item xs={12} sx={{p: "0 16px", marginBottom: "20px", display: 'flex', justifyContent: 'end'}}>
              {!timesheetId &&
                <Stack spacing={3} direction={"row"} justifyContent="flex-end">
                  <CancelButton/>
                  <SaveButton type={'submit'} disabled={!!timesheetId} onClick={async () => {
                    const isValid = await trigger();
                    if (isValid) {
                      const data = getValues();
                      await onSubmit(data);
                    } else {
                      scrollTo("name")
                    }
                  }}/>
                </Stack>
              }
            </Grid>
          </Grid>}
      </DialogTitle>
    </Dialog>
  )
}

export default TimesheetEdit;

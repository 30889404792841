import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import DashboardService from "../../../services/att/services/DashboardService";
import { useTranslation } from "react-i18next";
import * as echarts from "echarts";
const PieChart: React.FC<any> = (props) => {
  const { t, i18n } = useTranslation();
  const chartRef = useRef(null);
  const chartInstance = useRef<any>(null);


  const [data, setData] = useState([
    {
      id: 0, value: 0, name: t("common.Normal"),
      itemStyle: {
        color:"#2BA9DE"
      }
    },
    {
      id: 1, value: 0, name: t("common.Abnormal"), itemStyle: {
        color: "#F65555"
      }
    },
  ]);
  useEffect(() => {
    if (chartInstance.current) {
      setData([
        {
          id: 0, value: 0, name: t("common.Normal"),
          itemStyle: {
            color: "#2BA9DE"
          }
        },
        {
          id: 1, value: 0, name: t("common.Abnormal"), itemStyle: {
            color: "#F65555"
          }
        },
      ])
    }
  }, [i18n.language, props.refreshChart])

  useEffect(() => {
    if (chartInstance.current == null) {
      chartInstance.current = echarts.init(chartRef.current);
    }
    const option = {
      tooltip: {
        trigger: "item",
        show:false
      },
      legend: {
        // orient: "vertical",
        left: "center",
        itemGap:60
      },
      series: [
        {
          name:props.title,
          type: "pie",
          radius: ["55%", "75%"],
          center: ['50%', '50%'],
          data: data,
          visible: false,
          show: false,
          width: 0,
          height: 0,
          label: {
            show:false
          },
          emphasis: {
            disabled:true,
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };
    chartInstance.current.group = "echart";
    chartInstance.current.setOption(option);
    echarts.connect('echart')
  }, [data]);

  const resizeChart = () => {
    if (chartInstance.current) {
      chartInstance.current?.resize();
    }
  };

  useEffect(() => {
    // 监听窗口大小变化
    window.addEventListener("resize", resizeChart);
    // 组件卸载时移除事件监听
    return () => {
      window.removeEventListener("resize", resizeChart);
    };
  }, []);

  return (
    <Grid sx={{
      flexGrow: 1,
      height:"100%",
      width: '33%',
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems:'center'
    }}>
      <Grid>{props.title}</Grid>
      <div
        ref={chartRef}
        style={{
          position: "absolute",
          top: '0px',
          left:'0px',
          width: "100%",
          height:"100%",
          display: "flex",
          justifyContent: "center",
        }}
      ></div>
    </Grid>
  );
};

export default PieChart;

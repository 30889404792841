import React, {useRef} from "react";
import {GridColDef} from "@mui/x-data-grid";
import {Box, Grid, Stack} from "@mui/material";
import CustomDataGrid, {DataGridRef} from "../../../components/data-grid/CustomDataGrid";
import {HREndpoints} from "../../../services/hr/endpoints";
import {Add, RemoveRedEyeOutlined, DeleteForever} from "@mui/icons-material";
import WebURL from "../../../urls";
import CustomRowButton from "../../../components/data-grid/CustomRowButton";
import AvatarIcon from "@/components/avatar/AvatarIcon";
import EditIcon from "@mui/icons-material/Edit";
import PersonOffIcon from '@mui/icons-material/PersonOff';
import Dialog from "@/components/dialog";
import SnackbarUtil from "@/utils/SnackbarUtil";
import EmployeeService from "@/services/hr/EmployeeService";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom"
import DeviceService from '@/services/dms/DeviceService';
import {ReactComponent as IconEdit} from '@/assets/icons/IconEdit.svg';
import {ReactComponent as IconMarkResigned} from '@/assets/icons/IconMarkResigned.svg';
import {ReactComponent as IconDeletes} from '@/assets/icons/IconDeletes.svg';
import EmployeeView from "./components/EmployeeView";
import EmployeeNew from "./components/EmployeeNew";
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";

const EmployeeDataGrid: React.FC = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const dataGridRef = useRef<DataGridRef>();
  const employeeViewRef = useRef<any>({})
  const employeeNewRef = useRef<any>({})
  const columns: GridColDef[] = [
    {
      field: 'firstName', headerName: t("common.Person Name"), flex: 0.2, renderCell: (params) => {
        return (
          <Grid style={{display: "flex", alignItems: "center"}}>
            <AvatarIcon name={params.row?.firstName || params.row?.lastName}/>
            <Box style={{padding: '0 8px'}}>{params.row.firstName} {params.row.lastName}</Box>
          </Grid>
        )
      }
    },
    {field: 'employeeCode', headerName: t("common.Person ID"), type: "string", flex: 0.2},
    // {field: 'email', headerName: t("common.Email"), type: "string", minWidth: 200},
    // {field: 'phone', headerName: t("common.Mobile"), type: "string", minWidth: 180},
    {field: 'department_name', headerName: t("common.Department"), type: "string", flex: 0.3},
    {
      field: 'actions',
      hideSortIcons: true,
      flex: 0.3,
      headerName: t("common.action"),
      headerAlign: 'left',
      align: 'left',
      renderCell: (e) => {
        return (
          <Stack direction={"row"} spacing={1} sx={{marginLeft: "-5px"}}>
            <CustomRowButton insideSvgIcon={IconEdit} actionName={t("common.edit")} buttonProps={{
              key: "edit",
              onClick: () => {
                employeeViewRef?.current?.showDetail(e.row.id)
                // navigate(WebURL.HR_EMPLOYEE_VIEW, {state: {id: e.row.id}});
              }
            }}/>
            <CustomRowButton insideSvgIcon={IconMarkResigned} actionName={t("common.Resigned")} buttonProps={{
              key: "Resigned",
              onClick: async () => {
                Dialog.confirm({
                  // width: "650px",
                  title: t("hr.resignation.Resignation"),
                  content: t("hr.resignation.You sure to resign?", {value: e.row.firstName + " " + e.row.lastName}),
                  helpInfo: t("hr.resignation.Resign Tip"),
                  onConfirm: () => {
                    EmployeeService.resign(e.row.id).then((response) => {
                      SnackbarUtil.success(t('common.success'))
                      dataGridRef.current?.refresh();
                    });
                  }
                });
              }
            }}/>
            <CustomRowButton insideSvgIcon={IconDeletes} actionName={t("common.delete")} buttonProps={{
              key: "delete",
              onClick: async () => {
                Dialog.confirm({
                  title: t("common.Delete Employee"),
                  content: t("common.You sure to delete?", {value: e.row.firstName + " " + e.row.lastName}),
                  onConfirm: () => {
                    DeviceService.deleteEmpInDevice({personId: e.row.id}).then((response) => {
                      if (response?.code == "TDMSE1001") {
                        SnackbarUtil.error(t("common.Person in device text"), {
                          anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center'
                          }
                        })
                      } else if (response?.code == "TDMSE1002") {
                        SnackbarUtil.error(t("common.Deleting the person wrong"), {
                          anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center'
                          }
                        })
                      } else {
                        SnackbarUtil.success(t('common.success'))
                        dataGridRef.current?.refresh();
                      }
                    });
                  }
                });
              }
            }}/>
          </Stack>
        )
      }
    }
  ]
  const reloadTable = () => {
    dataGridRef.current?.refresh();
  }
  return (
    <>
      <CustomBreadcrumbs record={[{label: t("common.Person")}]}/>
      <CustomDataGrid
        uri={HREndpoints.HR_EMPLOYEE_URI}
        columns={columns}
        ref={dataGridRef}
        checkboxSelection={false}
        toolbar={{
          title: t("common.Person"),
          searchTips: t("common.Search by Person First Name"),
          breadcrumbs: [
            {breadcrumbName: t("common.Person"), path: WebURL.HR_EMPLOYEE}
          ],
          actions: [{
            key: 'add', iconFont: 'icon-ic_add', helpTxt: t("common.add"), callback: async () => {
              employeeNewRef?.current?.showAdd()
              // navigate(WebURL.HR_EMPLOYEE_NEW);
            }
          }]
        }}
      />
      <EmployeeView ref={employeeViewRef} reloadTable={reloadTable}></EmployeeView>
      <EmployeeNew ref={employeeNewRef} reloadTable={reloadTable}></EmployeeNew>
    </>
  )
}
export default EmployeeDataGrid

import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary, Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Stack,
  Checkbox,
  Typography
} from "@mui/material";
import {DataGrid, GridColDef, GridRowModes, useGridApiContext} from "@mui/x-data-grid";
import {ArrowForwardIosSharp} from "@mui/icons-material";
import {timeColumnType} from "../../../../components/data-grid/CustomColumnType";
import Util from "../../../../utils/Util";
import {object, TypeOf, z} from "zod";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import Tooltip, {TooltipProps, tooltipClasses} from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import QuestionMarkSharpIcon from '@mui/icons-material/QuestionMarkSharp';
import {styled} from '@mui/material/styles';
import GenericService from "../../../../services/GenericService";
import {ATTEndpoints} from "../../../../services/att/endpoints";
import {useTranslation} from "react-i18next";

type TimesheetProps = {}
type TimesheetSettingProps = {
  cycle: number;
  period: string;
  option?: string;
  timesheet: TimesheetProps[];
  view_data?: any;
  schedule_used?: any
  setOption?: any;
}

const CustomWidthTooltip = styled(({className, ...props}: TooltipProps) => (
  <Tooltip {...props} classes={{popper: className}}/>
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 750,
    backgroundColor: "white",
    border: "solid #36C96D 1px",
    color: "gray",
    fontSize: 15,
    lineHeight: 2,
    whiteSpace: "pre-wrap",
    minHeight: "170px",
    "& .MuiTooltip-arrow": {
      "&::before": {
        border: "solid #36C96D 1px",
        backgroundColor: "white",
      },
    },
  },
});

const TimesheetSetting = (props: TimesheetSettingProps, ref: any) => {
  const {t} = useTranslation();
  const [option, setOption] = React.useState('clocking');
  const Weekday = [t("att.timeSheet.Sunday"), t("att.timeSheet.Monday"), t("att.timeSheet.Tuesday"),
    t("att.timeSheet.Wednesday"), t("att.timeSheet.Thursday"), t("att.timeSheet.Friday"),
    t("att.timeSheet.Saturday")];
  const sheetSchema = object({
    cycle: z.number()
      .min(1, t("att.timeSheet.Minimum cycle is 1"))
      .max(12, t("att.timeSheet.Maximum cycle is 12")),
    period: z.string(),
    option: z.string()
  })
  const gridApiRef = useRef<any>();
  const param_id = props.view_data;
  const schedule_used = props.schedule_used;
  const TypeRadio = ({id, field, value}: Record<string, any>) => {
    const apiRef = useGridApiContext();
    gridApiRef.current = apiRef.current;
    return (
      <RadioGroup row={true} name={field} value={value} onChange={async (event, newValue) => {
        await apiRef.current.setEditCellValue({id, field, value: newValue})
      }}>
        <FormControlLabel value={"workday"} control={<Radio/>} label={t("att.timeSheet.Workday")} disabled={schedule_used!=0}/>
        <FormControlLabel value={"weekend"} control={<Radio/>} label={t("att.timeSheet.Weekend")} disabled={schedule_used!=0}/>
      </RadioGroup>
    )
  }
  const [EditColumns, SetEditColumns] = useState<GridColDef[]>([
    {field: "id", headerName: t("att.timeSheet.Number"), flex: 0.1},
    {
      field: "day", headerName: t("att.timeSheet.Day"), flex: 0.1, sortable: false,
      valueFormatter: (params) => {
        return Weekday[params.value % 7];
      }
    },
    {
      field: "check_in_start", headerName: t("att.timeSheet.Check In Start"), ...timeColumnType, flex: 0.15,
      sortable: false, editable: true, valueFormatter: (params) => {
        return Util.datetime2timeString(params.value);
      }
    },
    {
      field: "check_in", headerName: t("att.timeSheet.Check In"), ...timeColumnType, flex: 0.15,
      sortable: false, editable: true, valueFormatter: (params) => {
        return Util.datetime2timeString(params.value);
      }
    },
    {
      field: "check_out", headerName: t("att.timeSheet.Check Out"), ...timeColumnType, flex: 0.15,
      sortable: false, editable: true, valueFormatter: (params) => {
        return Util.datetime2timeString(params.value);
      }
    },
    {
      field: "check_out_end", headerName: t("att.timeSheet.Check Out End"), ...timeColumnType, flex: 0.15,
      sortable: false, editable: true, valueFormatter: (params) => {
        return Util.datetime2timeString(params.value);
      }
    },
    {
      field: "max_working_hours", headerName: t("att.timeSheet.Max working hours"), flex: 0.1, type: "number",
      sortable: false, editable: true, hide: true, headerAlign: "center", align: "center",
    },
    {
      field: "type",
      headerName: t("att.timeSheet.Type"),
      headerAlign: "center",
      align: "center",
      flex: 0.3,
      editable: true,
      renderEditCell: (params) => {
        return (
          <TypeRadio {...params} />
        )
      }
    }
    ,
  ])
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectOption = (event.target as HTMLInputElement).value
    setOption(selectOption);
    props.setOption(selectOption)
    sheetForm.setValue("option", selectOption)
    if (selectOption == "working") {
      EditColumns.filter(function (item) {
        if (item.field == "check_in_start" || item.field == "check_out_end" || item.field == "check_out"
          || item.field == "check_in") {
          item['hide'] = true
        }
        if (item.field == "max_working_hours") {
          item['hide'] = false
        }
      })
    } else if (selectOption == "clocking") {
      EditColumns.filter(function (item) {
        if (item.field == "check_in_start" || item.field == "check_out_end" || item.field == "check_out"
          || item.field == "check_in") {
          item['hide'] = false
        } else if (item.field == "max_working_hours") {
          item["hide"] = true
        }
      })
    }
    SetEditColumns(EditColumns.filter(function (item) {
      return item
    }))
  };
  const [timesheet, setTimesheet] = useState<any[]>(props.timesheet);
  const [expand, setExpand] = useState<boolean>(false);
  const [CycleError, setCycleError] = useState<boolean>(false);
  const [CycleErrorText, setCycleErrorText] = useState<string>("");
  type SheetInput = TypeOf<typeof sheetSchema>;
  const sheetForm = useForm<SheetInput>({
    defaultValues: {cycle: 1, period: "week", option: "clocking"},
    resolver: zodResolver(sheetSchema)
  })
  useImperativeHandle(ref, () => ({
    sheet: async () => {
      const isValid = await sheetForm.trigger();
      if (!isValid) {
        return {}
      }
      const newTimesheet: any[] = [];
      const editRows = gridApiRef.current?.state.editRows;
      Object.keys(editRows).map((key: string) => {
        const row = editRows[key];
        const {check_in_start, check_in, check_out, check_out_end, max_working_hours, type} = row;
        if (parseInt(key) <= timesheet.length){
          newTimesheet.push({
          day: parseInt(key),
          check_in_start: Util.datetime2timeString(check_in_start.value),
          check_in: Util.datetime2timeString(check_in.value),
          check_out: Util.datetime2timeString(check_out.value),
          check_out_end: Util.datetime2timeString(check_out_end.value),
          max_working_hours: max_working_hours.value,
          type: type.value
        })}
      })
      return {
        ...sheetForm.getValues(),
        sheet: newTimesheet.sort((a, b) => (parseInt(a.day) - parseInt(b.day)))
      }
    }
  }))

  const createTimesheet = (cycle: number, period: string) => {
    if (cycle < 1 || cycle == 0) {
      setCycleError(true);
      setCycleErrorText(t("att.timeSheet.Minimum cycle is 1"))
    } else if (cycle > 12) {
      setCycleError(true);
      setCycleErrorText(t("att.timeSheet.Maximum cycle is 12"))
    } else if (String(cycle).indexOf(".") > -1) {
      setCycleError(true);
      setCycleErrorText(t("att.timeSheet.Cycle must be an integer"))
    } else {
      setCycleError(false);
      setCycleErrorText("")
    }
    const newTimesheet: any[] = [];
    if (cycle >= 1 && cycle <= 12) {
      for (let i = 0; i < (7 * cycle); i++) {
        const day = i + 1
        if (day % 7 === 6 || day % 7 === 0) {
          newTimesheet.push({
            id: day,
            day: day.toString(),
            check_in_start: "08:30",
            check_in: "09:00",
            check_out: "18:00",
            check_out_end: "18:30",
            max_working_hours: 8,
            type: "weekend"
          })
        } else {
          newTimesheet.push({
            id: day,
            day: day.toString(),
            check_in_start: "08:30",
            check_in: "09:00",
            check_out: "18:00",
            check_out_end: "18:30",
            max_working_hours: 8,
            type: "workday"
          })
        }
      }
    }
    const preSheetLen = timesheet.length;
    const newSheetLen = newTimesheet.length;
    if (preSheetLen > newSheetLen) {
      setTimesheet(timesheet.slice(0, newSheetLen));
    } else {
      setTimesheet(timesheet.concat(newTimesheet.slice(preSheetLen, newSheetLen)))
    }
  }
  const getRowModesModel = () => {
    const rowModesModel: { [key: number]: { mode: GridRowModes } } = {};
    timesheet.map((item) => {
      rowModesModel[item.id] = {mode: GridRowModes.Edit}
    })
    return rowModesModel
  }
  useEffect(() => {
    sheetForm.watch((data, {name, type}) => {
      createTimesheet(data.cycle || 0, data.period || "week");
    });
    if (param_id) {
      GenericService.retrieve(ATTEndpoints.ATT_TIMESHEET_URI, `${param_id}`).then((res) => {
        sheetForm.setValue("cycle", res.data?.cycle);
        sheetForm.setValue("option", res.data?.pattern.option);
        setOption(res.data?.pattern.option)
        if (res.data?.pattern.option == "working") {
          EditColumns.filter(function (item) {
            if (item.field == "check_in_start" || item.field == "check_out_end" || item.field == "check_out" || item.field == "check_in") {
              item['hide'] = true
            }
            if (item.field == "max_working_hours") {
              item['hide'] = false
              item['editable'] = schedule_used == 0
            }
          })
          SetEditColumns(EditColumns.filter(function (item) {
            return item
          }))
        }
        if (res.data?.pattern.option == "clocking" && !res.data?.sheet[0].check_in_start) {
          EditColumns.filter(function (item) {
            if (item.field == "check_in_start" || item.field == "check_out_end") {
              item['hide'] = true
            }
          })
          SetEditColumns(EditColumns.filter(function (item) {
            return item
          }))
        }
        if (res.data?.pattern.option == "clocking") {
          EditColumns.filter(function (item) {
            if (item.field == "check_in_start" || item.field == "check_out_end" || item.field == "check_out" || item.field == "check_in") {
              item['editable'] = schedule_used == 0
            }
          })
          SetEditColumns(EditColumns.filter(function (item) {
            return item
          }))
        }
        const ViewTimesheet: any[] = [];
        {
          res.data?.sheet.map((item: any, index: any) => {
            ViewTimesheet[index] = {
              id: index + 1, day: (index + 1).toString(), type: item.type, check_in_start: item.check_in_start,
              check_in: item.check_in, check_out: item.check_out, check_out_end: item.check_out_end,
              max_working_hours: item.max_working_hours
            }
          })
        }
        setTimesheet(ViewTimesheet);
      })
    } else {
      createTimesheet(1, "week");
    }
  }, [sheetForm.watch])
  return (
    <Accordion
      expanded={expand}
      disableGutters={true}
      sx={{
        boxShadow: "none",
        border: "none",
        width: "80vw",
      }}
    >
      <AccordionSummary
        expandIcon={<ArrowForwardIosSharp sx={{fontSize: '0.9rem'}}/>} sx={(theme) => ({
        flexDirection: "row-reverse",
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
          transform: 'rotate(90deg)',
        },
        '& .MuiAccordionSummary-content': {
          marginLeft: theme.spacing(1),
        },
      })}
        onClick={() => setExpand(!expand)}
      >
        <Typography>
          {t("att.timeSheet.Timesheet")}
          <CustomWidthTooltip title={t("att.timeSheet.longText")} placement="bottom-end" arrow>
            <IconButton id='HelpIconButton'>
              {/* <QuestionMarkSharpIcon sx={{fontSize: 19, color: '#2BA9DE'}}/> */}
              <i className="icon iconfont icon-ic_about" style={{fontSize: 19, color: '#2BA9DE'}}></i>
            </IconButton>
          </CustomWidthTooltip>
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{
        borderTop: '1px solid rgba(0, 0, 0, .125)',
        minHeight: "450px",
        overflowY: "auto"
      }}>
        <Grid container={true} columnSpacing={6} rowSpacing={2} component={"form"}>
          <Grid item={true} xs={4}>
            <RadioGroup
              row={true}
              name={"option"}
              value={option}
              onChange={handleRadioChange}
            >
              <FormControlLabel sx={{
                '& .MuiFormControlLabel-label': {
                  fontSize: "14px",
                },
              }} value={"clocking"} control={<Radio/>} label={t("att.timeSheet.Clocking helpTxt")} disabled={schedule_used!=0}/>
              <FormControlLabel sx={{
                '& .MuiFormControlLabel-label': {
                  fontSize: "14px",
                },
              }} value={"working"} control={<Radio/>} label={t("att.timeSheet.Working helpTxt")} disabled={schedule_used!=0}/>
            </RadioGroup>
          </Grid>
          <Grid item={true} xs={5}>
            <Stack spacing={1} direction={"row"} alignItems={"center"} justifyContent={"flex-end"}>
              <Typography sx={{fontSize: "14px"}}>{t("att.timeSheet.Number of repetitions")}</Typography>：
              <TextField
                size={"small"}
                sx={{
                  '& .MuiOutlinedInput-input': {
                    fontSize: "14px",
                  },
                }}
                InputLabelProps={{
                  shrink: true
                }}
                disabled={schedule_used!=0}
                error={CycleError}
                helperText={CycleErrorText}
                {...sheetForm.register("cycle", {valueAsNumber: true})}
              />
            </Stack>
          </Grid>
          <Grid item={true} xs={3}>
            <Stack spacing={1} direction={"row"} alignItems={"center"} justifyContent={"center"}>
              <Typography sx={{fontSize: "14px"}}>{t("att.timeSheet.Cycle Period")}</Typography>
              ：<FormControlLabel sx={{
              '& .MuiFormControlLabel-label': {
                fontSize: "14px",
              },
            }} value={"week"} control={<Radio checked={true}/>}
                                 label={t("att.timeSheet.Week")} {...sheetForm.register("period")}/>
            </Stack>
          </Grid>
          <Grid item={true} xs={12}>
            <DataGrid
              sx={{
                '& .MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 400
                },
                '& .MuiDataGrid-cellContent': {
                  fontSize: "14px"
                },
                '& .MuiOutlinedInput-input': {
                  fontSize: "14px"
                },
                '& .MuiFormControlLabel-label': {
                  fontSize: "14px"
                }
              }}
              experimentalFeatures={{
                newEditingApi: true,
              }}
              editMode={"row"}
              autoHeight={true}
              disableColumnMenu={true}
              hideFooterPagination={true}
              hideFooterSelectedRowCount={true}
              columns={EditColumns}
              rows={timesheet}
              rowModesModel={getRowModesModel()}
              hideFooter={true}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
};
export default forwardRef(TimesheetSetting)

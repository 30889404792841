import { Box, Grid, Breadcrumbs, Typography, Drawer, InputAdornment, IconButton, Stack } from "@mui/material";
import TextField, { TextFieldProps as MuiTextFieldPropsType } from '@mui/material/TextField';
import { GridColDef, GridSelectionModel } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { HREndpoints } from "@/services/hr/endpoints";
import { useTranslation } from "react-i18next";
import { SaveButton } from "@/components/data-grid/CustomButton";
import CustomDataGrid from '@/components/data-grid/CustomDataGrid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import React from "react";
import {ReactComponent as IconPersonnel} from '@/assets/icons/IconPersonnel.svg';
export type EmpPickerProps = {
  multi?: boolean;
  single?: boolean;
  renderInput?: (props: MuiTextFieldPropsType) => React.ReactElement;
} & MuiTextFieldPropsType

const customDataGridStyle = {
  '& .MuiDataGrid-columnHeaders': { backgroundColor: '#F0F1F4!important', color: '#304669!important' },
  '.MuiDataGrid-columnHeader:focus-within': { outline: 'none' },
  '.MuiDataGrid-cell': { borderBottom: '0px', color: '#304669' },
  '.MuiDataGrid-cell:focus-within': { outline: 'none!important', color: '#2BA9DE!important' },
  '.MuiDataGrid-columnSeparator': { display: 'none !important' },
  '.MuiSvgIcon-root': { backgroundColor: 'transparent' },
  '.MuiDataGrid-root': { padding: '8px', borderRadius: '4px' },
  '.MuiDataGrid-row': { borderBottom: '1px solid #E7EBF0', maxHeight: '43px!important', minHeight: '43px!important' },
  '.MuiCheckbox-root': { color: '#D1D1D1' },
  '.MuiDataGrid-cellContent': {
    color: '#304669!important',
    fontFamily: 'Roboto',
    letterSpacing: '0.4px',
    fontSize: '14px'
  },
  '.MuiDataGrid-virtualScrollerRenderZone': { backgroundColor: '#FFFF' },
  '.MuiDataGrid-footerContainer': { display: 'block', width: '100%', border: '0px' },
  '.MuiTablePagination-spacer': { display: 'none' },
  '.MuiTablePagination-actions': { position: 'absolute', right: '0px', backgroundColor: '#FFF' },
  '.MuiTablePagination-displayedRows': { position: 'absolute', right: '90px' },
  '.MuiDataGrid-virtualScroller': { overflowY: 'auto !important' },
  '.MuiTablePagination-select': { backgroundColor: '#F0F1F4', borderRadius: '4px!important' },
  '.MuiTablePagination-toolbar': { paddingLeft: '0px' },
  '.MuiIconButton-root.Mui-disabled': { backgroundColor: '#F0F1F4', borderRadius: '0px' },
  // '.MuiIconButton-root': {borderRadius: '0px'},
  '.Mui-checked': { color: 'rgb(54, 201, 109)!important' },
};
const customRadioStyle = {
  '.Mui-checked': { color: '#2BA9DE !important' },
}
function EmpPicker(props: EmpPickerProps) {
  const { t } = useTranslation();
  const [gridData, setGridData] = useState([]);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [selectionData, setSelectionData] = useState<Record<string, any>>([]);
  const [open, setOpen] = React.useState(false);
  const { multi, renderInput, value, onChange, ...textFieldProps } = props
  const valueObj = value as Record<string, any>
  const valueObjs = value as Record<string, any>[]
  let displayValue = ""
  if (multi) {
    const emps = valueObjs ? valueObjs.map((emp: any) => `${emp?.firstName??""} ${emp?.lastName??""}`) : []
    displayValue = emps.join(',')
  } else {
    displayValue = valueObj ? `${valueObj?.firstName??""} ${valueObj?.lastName??""}` : ""
  }
  const columns: GridColDef[] = [
    {
      field: 'firstName', headerName: t("common.Person Name"), flex: 0.4, renderCell: (params) => {
        return (
          <Box>{params.row.firstName} {params.row?.lastName}</Box>
        )
      }
    },
    { field: 'employeeCode', headerName: t("common.Person Id"), type: "string", flex: 0.5 },
  ]
  const singleColumns: GridColDef[] = [
    {
      field: 'actions', hideSortIcons: true, flex: 0.1, headerName: '', headerAlign: 'center', align: 'center',
      renderCell: (params) => {
        return (
          <RadioGroup row value={selectedId} onChange={(event)=>radioChange(event.target.value)}>
            <FormControlLabel value={params.row.id} control={<Radio />} label="" sx={customRadioStyle}/>
          </RadioGroup>
        )
      }
    },
    {
      field: 'firstName', headerName: t("common.Person Name"), flex: 0.4, renderCell: (params) => {
        return (
          <Box>{params.row.firstName} {params.row?.lastName}</Box>
        )
      }
    },
    { field: 'employeeCode', headerName: t("common.Person Id"), type: "string", flex: 0.5 },
  ]
  const [selectedId, setSelectedId] = useState(value || '')
  const radioChange = (value: string) => {
    setSelectedId(value)
    setSelectionModel([value]);
    setSelectionData([value].map((id) => gridData.find((row: any) => row.id === id)))
  }

  const handleClose = () => {
    setOpen(false);
    setSelectedId('')
  }

  const handleSave = (value: any) => {
    setOpen(false);
    if (onChange) {
      if (multi) {
        onChange(value)
      } else {
        onChange(value[0])
      }
    }
    setSelectedId('')
  }

  const handleDataGridSave = (value: any) => {
    if (value) {
      setGridData(value)
    }
  }

  return (
    <>
      {
        props.renderInput ?
          <props.renderInput
            onClick={() => setOpen(true)}
            value={displayValue}
            {...textFieldProps}
          />
          :
          <TextField
            onClick={() => setOpen(true)}
            sx={{ width: '100%' }}
            value={displayValue}
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <IconButton sx={{ marginRight: "-10px" }}>
                  <IconPersonnel />
                </IconButton>
              </InputAdornment>
            }}
            {...textFieldProps}
          />

      }
      <Drawer
        anchor={"right"}
        open={open}
        onClose={handleClose}
      >
        <Box sx={{ height: '100%', width: '450px',  display:'flex',flexDirection:'column', position: 'relative', }}>
          <Grid sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '56px',
            padding: '0px 24px',
            borderBottom: '1px solid #F2F5F8',
          }}>
            <Grid>{t("common.Select person")}</Grid>
            <Grid>
              <i style={{ cursor: "pointer" }} className="icon iconfont icon-ic_close" onClick={handleClose}></i>
            </Grid>
          </Grid>
          <Box sx={{ height: 'calc(100% - 130px)', width: '100%' }}>
            <CustomDataGrid
              style={{height: 'calc(100% - 20px)', border: 0, width: '100%'}}
              uri={HREndpoints.HR_EMPLOYEE_URI}
              columns={props.single?singleColumns:columns}
              checkboxSelection={multi}
              rowsPerPageOptions={[10, 20, 50, 100]}
              sx={customDataGridStyle}
              onDataChange={handleDataGridSave}
              onSelectionModelChange={(ids) => {
                setSelectionModel(ids);
                setSelectionData(ids.map((id) => gridData.find((row: any) => row.id === id)))
              }}
              selectionModel={selectionModel}
              boxShadow={false}
              containerStyle={{
                paddingRight: '0'
              }}
              toolbar={{
                title: "",
                filter: false,
                refresh: false,
                searchTips: t("common.Search by Person First Name"),
              }}
            />
          </Box>
          <Grid sx={{borderTop: '1px solid #F2F5F8', position: 'absolute', bottom: '12px', left: '0', width: '100%'}}>
            <Grid style={{paddingTop: '12px', paddingLeft: '24px'}}>
              <SaveButton
                children={t("common.confirm")}
                sx={{ width: '160px', textTransform: "none"}}
                onClick={() => {
                  handleSave(selectionData)
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Drawer>
    </>
  )
}

export default EmpPicker;

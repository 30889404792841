import React, { useEffect, useImperativeHandle, useState, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { object, TypeOf, z } from "zod";
import { useSnackbar } from "notistack";
import { Grid, Stack, Drawer } from "@mui/material";
import { zodResolver } from "@hookform/resolvers/zod";
import EmployeeService from "@/services/hr/EmployeeService";
import { CancelButton, SavingButton } from "@/components/data-grid/CustomButton";
import EmployeeForm from "./EmployeeForm";

const EmployeeView = (props: any, ref: any) => {
  const { t } = useTranslation();
  const employeeValidate = object({
    code: z.string({ required_error: t("common.This field is required!")}).min(1, t("common.This field is required!"))
        .max(14, t("common.The length of field cannot exceed digits", {number: 14})),
    firstName: z.string({ required_error: t("common.This field is required!")}).min(1, t("common.This field is required!"))
        .max(25, t("common.The length of field cannot exceed digits", {number: 25})),
    lastName: z.string({ required_error: t("common.This field is required!")}).min(1, t("common.This field is required!"))
        .max(25, t("common.The length of field cannot exceed digits", {number: 25})),
    departmentIdOrCode: z.string().optional(),
    dept: z.object({
      id: z.string(),
      name: z.string(),
    }, { required_error: t("common.This field is required!"), invalid_type_error: t("common.This field is required!")} ),
  })
  type employeeInput = TypeOf<typeof employeeValidate>;
  const [state, setState] = useState({ id: ""}) // 编辑人员id值
  const [open, setOpen] = useState(false)
  const onClose = () => {
    employeeForm.reset()
    setOpen(false)
  }
  useImperativeHandle(ref, () => ({
    showDetail: async (id:string) => {
      setState({id})
      setOpen(true)
    }
  }));

  const { enqueueSnackbar } = useSnackbar();
  const [saving, setSaving] = React.useState<boolean>(false)
  const [oldData, setOldData] = React.useState<any>()
  const title = t("common.Edit Person")

  useEffect(() => {
    if (state.id) {
      EmployeeService.retrieve(state.id || "").then((res) => {
        const emp = res.data.data
        employeeForm.reset({
          code: emp?.employeeCode,
          firstName: emp?.firstName,
          lastName: emp?.lastName,
          departmentIdOrCode: emp?.departmentIdOrCode,
          dept: {id: emp?.departmentIdOrCode, name: emp?.department_name}
        })
        setOldData({
          firstName: emp?.firstName,
          lastName: emp?.lastName,
          dept: {id: emp?.departmentIdOrCode, name: emp?.department_name}
        })
      })
    }
  }, [state]);

  const employeeForm= useForm<employeeInput>({
    resolver: zodResolver(employeeValidate),
    defaultValues: {
      code: '',
      firstName: '',
      lastName: '',
      dept: {id: '', name: ''},
      departmentIdOrCode: ''
    }
  });

  const onSubmit = async (event: any) => {
    setSaving(true)
    try {
      // employeeForm.unregister("code")
      const values = employeeForm.getValues()
      if (values.firstName.includes('=') || values.lastName.includes('=')) {
        enqueueSnackbar(t("common.Equal signs cannot appear in the name!"), {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        });
        return
      }
      values.departmentIdOrCode = values.dept.id
      const data = {
        ...values,
        oldData: oldData
      }
      // update employee
      const result = await EmployeeService.update(state.id || "", data);
      if (result.status < 400) {
        enqueueSnackbar(t("common.Person updated successfully."), {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        });
        if (props.reloadTable) {
          props.reloadTable()
        }
        onClose()
      }
    } catch (e) {
      console.log("error===", e)
    } finally {
      setSaving(false)
    }
  }

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={onClose}
    >
      <Grid sx={{
        minWidth: '400px',
        minHeight: '100vh',
        display:'flex',
        flexDirection:'column',
        justifyContent: 'flex-start',
        position: 'relative',
      }}>
        <Grid sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '56px',
          padding: '0px 24px',
          borderBottom: '1px solid #F2F5F8',
        }}>
          <Grid>{ title }</Grid>
          <Grid>
            <i style={{ cursor: "pointer" }} className="icon iconfont icon-ic_close" onClick={onClose}></i>
          </Grid>
        </Grid>
      <EmployeeForm employeeForm={employeeForm} isEditAction={true}/>
      <Grid sx={{borderTop: '1px solid #F2F5F8', position: 'absolute', bottom: '12px', left: '0', width: '100%'}}>
          <Grid style={{paddingTop: '12px', paddingLeft: '24px'}}>
            <Stack spacing={3} direction={"row"} justifyContent="start" >
              <CancelButton onClick={onClose} />
              <SavingButton loading={saving} onClick={employeeForm.handleSubmit(onSubmit)}/>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Drawer>
  )
}
export default forwardRef(EmployeeView);

import { ngteco_timezones } from "@/components/resource/timezone";
import { getOrganSites } from "@/store/slices/Site";
import { getOrganZones } from "@/store/slices/Zone";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Drawer,
  Grid,
  Stack,
  useTheme,
  Avatar,
  Alert,
  Box,
  Typography,
  Autocomplete,
  TextField,
  CircularProgress,
} from "@mui/material";
import AdUnitsIcon from "@mui/icons-material/AdUnits";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { object, TypeOf, z } from "zod";
import ControlAutoComplete from "@/components/hook-form/ControlAutoComplete";
import {
  CancelButton,
  SavingButton,
} from "@/components/data-grid/CustomButton";
import DeviceService from "@/services/dms/DeviceService";
import WebURL from "@/urls";
import SiteService from "@/services/location/SiteService";
import ControlledTextFiled from "@/components/ngteco-hook-form/ControlledTextFiled";
import ZoneService from "@/services/location/ZoneService";
import NGInputLabel from "@/components/ngteco-form/NGInputLabel";
import SnackbarUtil from "@/utils/SnackbarUtil";

const customDrawerStyle = {
  "& .MuiDrawer-paper": { width: "30vw" },
};

type DeviceBindingProps = {
  // visible: boolean;
  // onClose: () => void;
  cloaseDrawer: (isReloadTable: boolean) => void;
  device?: { deviceId: string; sn: string };
};

const DeviceBinding: React.FC<DeviceBindingProps> = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const deviceSchema = object({
    site: z.object(
      {
        id: z.string(),
        name: z.string(),
        address: z.object({
          timeZone: z.string().nullish(),
        }),
      },
      {
        required_error: t("common.This field is required!"),
        invalid_type_error: t("common.This field is required!"),
      }
    ),
    // zone: z.object({
    //   id: z.string(),
    //   name: z.string()
    // }, { required_error: t("common.This field is required!"), invalid_type_error: t("common.This field is required!")} ),
    timezone: z.union([
      z.object(
        {
          value: z.string(),
          code: z.string(),
          group: z.string(),
          name: z.string(),
        },
        {
          required_error: t("common.This field is required!"),
          invalid_type_error: t("common.This field is required!"),
        }
      ),
      z.string({ required_error: t("common.This field is required!") }),
    ]),
  });
  type deviceType = TypeOf<typeof deviceSchema>;

  const navigate = useNavigate();
  const deviceForm = useForm<deviceType>({
    resolver: zodResolver(deviceSchema),
  });
  const sites: { id: string; name: string; address: Record<string, any> }[] =
    useSelector((state: any) => state.site.sites);
  // const zones: {id: string, name: string}[] = useSelector((state: any) => state.zone.zones);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch<any>(getOrganSites());
  }, []);
  const [loading, setLoading] = useState<boolean>(false);
  const timezoneList = ngteco_timezones();
  const [timeZone, setTimeZone] = useState<string>();
  const [timezoneEmpty, setTimezoneEmpty] = useState<boolean>(false);
  return (
    <Grid
      sx={{
        width: "100%",
        minWidth:'380px',
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Grid>
        <Grid
          sx={{ marginTop: "20px", fontSize: "1.6rem", textAlign: "center" }}
        >
          {t("dms.tip01")}
        </Grid>
        <Stack spacing={2}>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "16px",
            }}
          >
            <Grid
              sx={{ textAlign: "center", color: theme.palette.primary.main }}
            >
              {/* <CheckCircle sx={{width: '3vw', height: '3vw'}} /> */}
              {/* <Grid> Added Successfully </Grid> */}
            </Grid>
          </Grid>
          <Grid>
            <Stack direction="row" spacing={2}>
              <Avatar sx={{ width: 50, height: 50 }}>
                <AdUnitsIcon sx={{ fontSize: 30 }} color="action" />
              </Avatar>
              <Box sx={{ fontSize: 16, display: "flex", alignItems: "center" }}>
                <Box sx={{ color: "gray" }}>
                  {t("dms.Serial Number")}: {props.device?.sn}
                </Box>
              </Box>
            </Stack>
            <Box sx={{marginTop:'12px'}}>
              <Typography gutterBottom>
                {t("dms.tip02")}
                <br />
              </Typography>
              <Typography gutterBottom color={theme.palette.primary.main}>
                {t("dms.tip03")}
              </Typography>
            </Box>
            <Box component={"form"}>
              <Stack sx={{ lineHeight: "5vh", marginTop: "12px" }}>
                <ControlAutoComplete
                  label={t("dms.Bind Site")}
                  name={"site"}
                  form={deviceForm}
                  required={true}
                  options={sites}
                  onChange={(value) => {
                    const timezone = value?.address.timeZone;
                    if (timezone) {
                      deviceForm.setValue(
                        "timezone",
                        timezoneList.find((item) => item.value === timezone)
                          ?.name || `UTC${timezone}`
                      );
                      setTimezoneEmpty(false);
                    } else {
                      setTimezoneEmpty(true);
                    }
                  }}
                />
                {timezoneEmpty ? (
                  <Grid
                    sx={{
                      marginTop: "12px",
                    }}
                  >
                    <ControlAutoComplete
                      label={t("dms.Site Timezone")}
                      name={"timezone"}
                      form={deviceForm}
                      options={timezoneList}
                      required={true}
                      groupBy={(option) => option.group}
                      isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                      }
                      onChange={({ value }) => {
                        setTimeZone(value);
                        deviceForm.setValue("timezone", value);
                      }}
                    />
                  </Grid>
                ) : (
                  <Grid
                    sx={{
                      marginTop: "12px",
                    }}
                  >
                    <ControlledTextFiled
                      label={t("dms.Site Timezone")}
                      fullWidth
                      name={"timezone"}
                      hookForm={deviceForm}
                      InputProps={{
                        readOnly: true,
                        // disabled: true,
                      }}
                    />
                  </Grid>
                )}
              </Stack>
            </Box>
          </Grid>
        </Stack>
      </Grid>
      <Grid
        sx={{
          padding: "5px",
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <CancelButton
          sx={{ minWidth: "120px", textTransform: "none" }}
          onClick={() => {
            deviceForm.reset({});

            props.cloaseDrawer(false);

            // props.onClose();
          }}
        />

        <SavingButton
          loading={loading}
          sx={{ minWidth: "120px", textTransform: "none" }}
          onClick={async () => {
            setLoading(true);
            try {
              const isValid = await deviceForm.trigger();
              if (isValid) {
                const values = deviceForm.getValues();
                // set regtistartion device default
                const origin = {
                  ...props.device,
                  siteId: values.site.id,
                  registrationDevice: 1,
                };
                const site_res = timezoneEmpty
                  ? await SiteService.update(values.site.id, {
                    address: {
                      timeZone,
                      city: "default",
                      addressLine1: "default",
                      siteId: values.site.id,
                    },
                  })
                  : {};
                const device_res = await DeviceService.deviceBinding(origin).then((res) => {
                  if (res.status < 400) {
                    SnackbarUtil.success(t("common.success"))
                  }
                  else{
                    SnackbarUtil.error(res.data?.message)
                  }
                });
                props.cloaseDrawer(true);
                //  props.onClose();
                //  navigate(WebURL.DMS_DEVICE);
              }
            } catch (e) {
              console.log("eeeeeeeeee", e);
            } finally {
              setLoading(false);
            }
          }}
        />
      </Grid>

      {/* <Drawer sx={customDrawerStyle} anchor="right" open={props.visible} onClose={props.onClose}>

      </Drawer> */}
    </Grid>
  );
};

export default DeviceBinding;
